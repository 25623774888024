<template>
  <div class="formSubmit_mobile widthBox_mobile">
    <baidu-map
      ak="7SPGg8W3wlSegDm3vZGeXXGfjxCaISFV"
      :center="center"
      :zoom="zoom"
      :Point="Point"
      class="bm-view"
    >
      <BmMarker :position="Point"></BmMarker>
    </baidu-map>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
export default {
  name: "formSubmit",
  data() {
    return {
      imgUrl: "",
      center: { lng: 113.750387, lat: 23.03119 },
      Point: {
        lng: 113.750387,
        lat: 23.03119,
      },
      zoom: 20,
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
  },

  components: {
    BaiduMap,
    // BmScale,
    // BmNavigation,
    // BmMarkerClusterer,
    BmMarker,
    // BmInfoWindow
  },
};
</script>

<style scoped>
.formSubmit_mobile {
  height: 160px;
}

.bm-view {
  height: 160px;
}
</style>
