<template>
  <section class="banner bannerBg5">
    <div class="banner_box widthBox">
      <div class="banner_cooperationTitle title">
        {{ title }}
      </div>
      <div class="banner_cooperationDescription">
        <div>
          {{ content1 }}
        </div>
        <div>
          {{ content2 }}
        </div>
      </div>
      <div class="banner_list">
        <el-image
          v-for="(item, index) in cooperationList"
          :key="index"
          :src="imgUrl + item.image"
          lazy
        ></el-image>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "partner",
  data() {
    return {
      imgUrl: "",
      carouselIndex: 0,
      cooperationList: [
        {
          image: "partner-aly.png",
          id: 0,
        },
        {
          image: "partner-bxjp.png",
          id: 1,
        },
        {
          image: "partner-fzg.png",
          id: 4,
        },
        {
          image: "partner-gsj.png",
          id: 5,
        },
        {
          image: "partner-hw.png",
          id: 6,
        },
        {
          image: "partner-hy.png",
          id: 7,
        },
        {
          image: "partner-lfy.png",
          id: 8,
        },
        {
          image: "partner-mf.png",
          id: 9,
        },
        {
          image: "partner-ah.png",
          id: 10,
        },
        {
          image: "partner-dj.png",
          id: 12,
        },
        {
          image: "partner-dx.png",
          id: 13,
        },
        {
          image: "partner-fitinn.png",
          id: 14,
        },
        {
          image: "partner-gots.png",
          id: 15,
        },
        {
          image: "partner-mxhl.png",
          id: 16,
        },
        {
          image: "partner-ssjj.png",
          id: 17,
        },
        {
          image: "partner-swj.png",
          id: 18,
        },
        {
          image: "partner-tj.png",
          id: 19,
        },
        {
          image: "partner-tlh.png",
          id: 20,
        },
        {
          image: "partner-cxkj.png",
          id: 21,
        },
        {
          image: "partner-tx.png",
          id: 22,
        },
        {
          image: "partner-ut.png",
          id: 23,
        },
        {
          image: "partner-wywhq.png",
          id: 24,
        },
        {
          image: "partner-xc.png",
          id: 25,
        },
        {
          image: "partner-ch.png",
          id: 26,
        },
        {
          image: "partner-ty.png",
          id: 27,
        },
        {
          image: "partner-whglm.png",
          id: 28,
        },
        {
          image: "partner-whhjj.png",
          id: 29,
        },
        {
          image: "partner-whlsdl.png",
          id: 30,
        },
        {
          image: "partner-whsn.png",
          id: 31
        },
        {
          image: "partner-yc.png",
          id: 32,
        },
      ],
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
  },
  methods: {},
  props: ["title", "content1", "content2"],
};
</script>

<style scoped>
.bannerBg5 {
  position: relative;
  color: #000;
  text-align: center;
}
.bannerBg5 .banner_cooperationTitle {
  font-size: 2rem;
}
.bannerBg5 .banner_cooperationDescription {
  color: #333333;
  line-height: 3rem;
  margin-top: 1.5rem;
}
.banner_list {
  width: 100%;
  padding-bottom: 40px;
  box-sizing: border-box;
}

@media only screen and (max-width: 414px) {
  .bannerBg5 .banner_cooperationDescription {
    line-height: 2rem;
    margin-top: 2rem;
  }
}
@media only screen and (max-width: 320px) {
  .bannerBg5 .banner_cooperationDescription {
    line-height: 2rem;
    margin-top: 2rem;
  }
}
</style>
