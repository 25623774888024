<template>
  <div id="footer" class="footer">
    <div class="widthBox">
      <el-row class="footer_main">
        <el-col :span="17">
          <img
            :src="imgUrl + 'vrText.png'"
            alt="顾诚智能"
            class="footer_vrText"
          />
          <ul class="footer_list">
            <li class="footer_listItem">
              <a href="http://www.513dvr.com/ourselves">关于顾诚</a>
              <a href="http://www.513dvr.com/ourselves">公司介绍</a>
              <a href="http://www.513dvr.com/product">产品介绍</a>
              <a href="http://www.513dvr.com/newsHome">媒体中心</a>
              <router-link to="callMe">联系我们</router-link>
            </li>
            <li class="footer_listItem">
              <a href="http://www.513dvr.com/caseShow">行业应用</a>
              <a href="http://www.513dvr.com/caseShow?id=vrExhibition"
                >企业展厅</a
              >
              <a href="http://www.513dvr.com/caseShow?id=vrMuseum">数字文博</a>
              <a href="http://www.513dvr.com/caseShow?id=vrHotel">民宿酒店</a>
              <a href="http://www.513dvr.com/caseShow?id=vrHouse"
                >V&nbsp;R&nbsp;看房</a
              >
              <a href="http://www.513dvr.com/caseShow?id=moreIndustry"
                >更多行业</a
              >
            </li>
            <li class="footer_listItem">
              <a href="http://www.513dvr.com/servicePlan">服务方案</a>
              <a href="http://www.513dvr.com/servicePlan?id=service"
                >服务流程</a
              >
              <a href="http://www.513dvr.com/servicePlan?id=offerPlan"
                >报价方案</a
              >
            </li>
            <li class="footer_listItem">
              <a>友情链接</a>
              <a href="http://www.good2011.com/">VR网站</a>
              <a href="http://www.true3dvr.cn/">3D相机</a>
            </li>
          </ul>
        </el-col>
        <el-col :span="7" class="footer_contact">
          <div class="footer_contactWx">
            <img :src="imgUrl + 'qrCode.jpg'" class="formUp_qrCode" />
            <div class="formUp_wx">
              <img :src="imgUrl + 'footer-wechat.png'" />
              关注我们
            </div>
          </div>
          <div class="footer_info">
            <div class="footer_info_item">
              <img :src="imgUrl + 'footer-phone.png'" />
              <span>0769-88993721</span>
            </div>
            <div class="footer_info_item">
              <img :src="imgUrl + 'footer-mobile.png'" />
              <span>13922304039 / 肖先生</span>
            </div>
            <router-link class="callMe" tag="span" to="callMe"
              >联系我们</router-link
            >
          </div>
        </el-col>
      </el-row>
      <el-row class="footer_company">
        <el-col>
          <a style="color: #ffffff" href="https://beian.miit.gov.cn">
            Copyright © {{ year }} 广东顾诚智能科技有限公司，All Rights
            Reserved. 粤ICP备16107832号</a
          >
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-footer",
  data() {
    return {
      imgUrl: "",
      year: "",
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    let years = new Date();
    this.year = years.getFullYear();
  },
  methods: {
    // 锚点
    anchor(url, val) {
      this.$router.push({
        path: url,
        query: { id: val },
      });
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  display: flex;
  height: 25rem;
  color: #fff;
  background: #141621;
}
.footer .footer_vrText {
  padding-top: 5rem;
}
.footer .footer_list {
  font-size: 0.9rem;
  padding-top: 4rem;
}
.footer .footer_list .footer_listItem {
  padding-bottom: 2rem;
  display: flex;
}
.footer .footer_list a {
  width: 6rem;
  opacity: 0.6;
  cursor: pointer;
  color: #fff;
  font-weight: normal;
}
.footer .footer_list .footer_listItem a:first-child {
  opacity: 1;
}
.footer .footer_contact {
  display: flex;
  padding-top: 8rem;
}
.footer .formUp_qrCode {
  width: 6rem;
  height: 6rem;
}
.footer .formUp_wx {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  justify-content: space-between;
  width: 6rem;
  margin-top: 0.2rem;
}
.footer .footer_contact {
  display: flex;
  justify-content: space-between;
}
.footer .footer_info_item {
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
}
.footer .footer_info_item img {
  padding-right: 0.3rem;
}
.footer .callMe {
  width: 6rem;
  height: 2.5rem;
  border-radius: 2rem;
  border: solid 1px #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  margin-top: 1rem;
  cursor: pointer;
}
.footer_company {
  border-top: 1px solid #fff;
  opacity: 0.4;
  text-align: center;
  padding-top: 1rem;
  font-size: 0.9rem;
}
</style>
