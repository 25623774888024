<template>
  <div class="home">
    <v-header></v-header>
    <section class="home_banner flex">
      <!-- <video
        loop="loop"
        ref="homeVideo"
        muted
        class="banner_video"
        autoplay="autoplay"
        :src="imgUrl + 'home-banner.mp4'"
      ></video> -->
      <div class="home_banner-text flex">
        <p class="home_banner-title" style="margin-top: 35%">数字孪生</p>
        <p class="home_banner-title" style="margin-top: 2rem; margin-right: 15px">
          世界零距离
        </p>
        <p class="home_banner-desc" style="margin-top: 40px; letter-spacing: 5px">
          三维可视化服务提供商
        </p>
        <p class="home_banner-desc" style="margin-top: 20px">3000元起给你做VR</p>
        <div class="home_banner-btn flex">
          <button @click="vrShow">点击体验</button>
          <button @click="dialogShow = true">预约演示</button>
        </div>
      </div>
    </section>
    <section class="home_product widthBox_mobile">
      <div class="home_content-title">
        <p class="title_big">产品中心</p>
        <p class="title_small">专注3DVR实景技术服务&nbsp;&nbsp;为您营销增值</p>
      </div>
      <div class="home_product-item-1" @click="navTo('/product_mobile', 'centre')">
        <div class="product-item-pic">
          <img :src="imgUrl + 'index-product1.jpg'" alt="VR实景空间" />
        </div>
        <div class="item-hint"></div>
        <p class="product-item-font-big">
          VR实景空间 <img :src="imgUrl + 'arrow-white.png'" />
        </p>
        <p class="product-item-font-small">
          <span>在线还原复刻实景&nbsp;&nbsp;打造沉浸式漫游体验</span>
        </p>
      </div>
      <div class="home_product-item-2" @click="navTo('/product_mobile', 'kernel')">
        <div class="product-item-pic">
          <img :src="imgUrl + 'index-product2.jpg'" alt="VR实景空间" />
        </div>
        <div class="item-hint"></div>
        <p class="product-item-font-big">
          3D模型产品展示 <img :src="imgUrl + 'arrow-white.png'" />
        </p>
        <p class="product-item-font-small">
          <span>720°全方位产品展示&nbsp;&nbsp;多形态一键看齐</span>
        </p>
      </div>
      <div class="home_product-item-2" @click="navTo('/product_mobile', 'facility')">
        <div class="product-item-pic">
          <img :src="imgUrl + 'index-product3.jpg'" alt="VR实景空间" />
        </div>
        <div class="item-hint"></div>
        <p class="product-item-font-big">
          个性化功能定制 <img :src="imgUrl + 'arrow-white.png'" />
        </p>
        <p class="product-item-font-small">
          <span>自定义通信、大数据统计等多功能定制</span>
        </p>
      </div>
    </section>
    <section class="home_case widthBox_mobile">
      <div class="home_content-title">
        <p class="title_big">行业案例</p>
        <p class="title_small">3D实景技术已在多个领域广泛应用</p>
      </div>

      <div class="home_case-item-1" @click="navTo('/caseShow_mobile', 'vrExhibition')">
        <div class="case-item-pic">
          <img :src="imgUrl + 'index-case1.jpg'" alt="企业展厅" />
        </div>
        <div class="item-hint"></div>
        <p class="case-item-font-big">企业展厅</p>
        <img class="case-item-arrow" :src="imgUrl + 'arrow-white.png'" />
      </div>
      <div class="home_case-small">
        <div
          class="home_case-item-2"
          v-for="(item, index) in caseList"
          :key="index"
          @click="navTo('/caseShow_mobile', item.mid)"
        >
          <div class="case-item-pic">
            <img :src="imgUrl + item.picUrl" :alt="item.title" />
          </div>
          <div class="item-hint"></div>
          <p class="case-item-font-big">{{ item.title }}</p>
          <img class="case-item-arrow" :src="imgUrl + 'arrow-white.png'" />
        </div>
      </div>
    </section>
    <section class="home_issue widthBox_mobile">
      <div class="home_content-title">
        <p class="title_big">常见问题</p>
        <p class="title_small">
          常见问题&nbsp;&nbsp;百问百答&nbsp;&nbsp;更多问题请联系咨询
        </p>
      </div>

      <div
        class="home_issue-item"
        :class="issueIndex == index ? 'active' : 'unactive'"
        v-for="(item, index) in issueList"
        :key="index"
        @click="issueIndex = index"
      >
        <div class="home_issue-title flex">
          <p class="question-item-num">{{ item.id }}</p>
          <p class="question-item-text">{{ item.text }}</p>
          <div class="question-item-arrow">
            <div></div>
          </div>
        </div>
        <div class="home_issue-answer">
          <p class="home_issue-answer-text">{{ item.answer }}</p>
        </div>
      </div>
    </section>
    <v-footer-mobile></v-footer-mobile>
    <board_mobile></board_mobile>
    <popup v-model="iframeShow" position="center" @input="vrCloseShow">
      <iframe
        class="popup-iframe"
        :class="{ safari: isSafari }"
        :style="{ width: iframeWidth, height: iframeHeight }"
        src="http://my.513dvr.com/view/LjMqvQ2xHfj.html"
        frameborder="0"
      ></iframe>
      <i class="popup-close el-icon-circle-close" @click="vrCloseShow"></i>
    </popup>
    <popup v-model="dialogShow" position="bottom" @input="hide">
      <div class="board-form">
        <div class="popup-title">
          <div></div>
          <p>免费预约演示</p>
          <p class="popup-btn-hide" @click="hide"></p>
        </div>
        <el-form class="popup-form" :model="insertForm">
          <el-form-item>
            <el-input
              prefix-icon="el-icon-user-solid"
              v-model="insertForm.user_name"
              placeholder="如何称呼您"
            >
              <p slot="suffix" class="required">*</p>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              prefix-icon="el-icon-phone"
              v-model="insertForm.user_tel"
              placeholder="您的手机号码"
            >
              <p slot="suffix" class="required">*</p>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="insertForm.user_email" placeholder="您的电子邮箱" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="insertForm.user_address" placeholder="您所在的地区" />
          </el-form-item>
          <el-form-item class="popup-form-center">
            <el-input
              class="popup-center"
              v-model="insertForm.user_company"
              placeholder="您的公司名称"
            />
          </el-form-item>
          <el-form-item class="popup-form-center">
            <el-input
              type="textarea"
              class="popup-center"
              v-model="insertForm.user_desc"
              placeholder="您的留言"
            />
          </el-form-item>
        </el-form>
        <div class="popup-btn">
          <p>我们保证您填写的信息不会泄露给第三方；提交申请后，我们会尽快与您联系！</p>
          <button class="popup-btn-submit" @click="submitInsert">提交申请</button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import header from "../../components/header";
import footer_mobile from "../../components/footer_mobile";
import board_mobile from "../../components/board_mobile";
import popup from "../../components/popup";

export default {
  name: "home_mobile",
  data() {
    return {
      imgUrl: "",
      caseList: [
        {
          id: "2",
          picUrl: "index-case2.jpg",
          title: "数字文博",
          mid: "vrMuseum",
        },
        {
          id: "3",
          picUrl: "index-case3.jpg",
          title: "民宿酒店",
          mid: "vrHotel",
        },
        {
          id: "4",
          picUrl: "index-case4.jpg",
          title: "VR看房",
          mid: "vrHouse",
        },
        {
          id: "5",
          picUrl: "index-case5.jpg",
          title: "更多行业",
          mid: "moreIndustry",
        },
      ],
      issueList: [
        {
          id: "01",
          text: "VR实景漫游和全景照片的区别是什么？",
          answer:
            "VR实景漫游是基于真实空间还原的空间三维模型，而全景照片是通过对不同角度和方位拍摄的照片进行拼接的二维照片。\n\n与全景照片相比，三维全景照片具有以下特点：\n1. 可以生成被扫描场景和空间的整体模型，并可以在网页中观看；\n2. 可以在空间中沉浸式漫游行走，而非全景照片不同点位之间的图像切换；\n3. 可以测量空间尺寸。",
        },
        {
          id: "02",
          text: "使用VR实景漫游，比起原来的照片和视频的好处是？",
          answer:
            "VR实景漫游是一种全新的浏览体验，比照片能提供更加真实的体验，沉浸式漫游如同身临其境，更加丰富的信息，比视频更加自由，看哪里点哪里。",
        },
        {
          id: "03",
          text: "顾诚提供的服务主要有什么？",
          answer:
            "顾诚智能自主研发VR和3D重建等技术以及云端快速自动化三维数字建模服务。同时，也可以为有空间还原建模需求的客户，提供一站式的上门服务。",
        },
        {
          id: "04",
          text: "顾诚的漫游空间可以带来哪些好处？",
          answer:
            "（1）可以全方位展示您的特色空间，方便您的目标客户在空间内自由行走。\n（2）可以升级您的营销方案，在空间内通过图文信息、视频信息、语音信息等与客户互动，并将场景信息展示给观众。\n（3）可以通过您的微信公众号、网站、朋友圈、APP快速分享空间，同时不需要安装任何插件或者应用。",
        },
        {
          id: "05",
          text: "3D实景是以什么文件交付给客户？",
          answer:
            "场景主要以一个链接的形式发送给您，您可以在网站中、微信号、APP中嵌入这个链接，将生成的场景实现远程分享。",
        },
        {
          id: "06",
          text: "扫描一个空间大约需要多久时间？",
          answer:
            "扫描拍摄的时间根据您需要拍摄的空间面积大小决定，一般来说，拍摄面积大小在300㎡以内的小空间，最快30分钟就可以完成扫描，完成扫描后需要根据您的需求进行建模，后期处理等工作。",
        },
      ],
      issueIndex: 0,
      iframeShow: false,
      // 预约咨询
      insertForm: {
        user_name: "",
        user_tel: "",
        user_grade: 0,
        user_address: "",
        user_desc: "",
        user_company: "",
        user_email: "",
      },
      dialogShow: false,
      isSafari: false,
      scrollY: 0,
      bodyStyle: "",
      iframeWidth: 0,
      iframeHeight: 0,
      oldScrollTop: 0,
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;

    if (/Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent)) {
      this.isSafari = true;
    } else {
      window.addEventListener("scroll", this.scrolling);
    }
    this.iframeWidth = window.innerWidth + "px" || "100vw";
    this.iframeHeight = window.innerHeight + "px" || "100vh";
  },
  methods: {
    vrShow() {
      this.iframeShow = true;
      this.stopBodyScroll(true);
      document.getElementById("nb_icon_wrap").style.setProperty("display", "none");
    },
    vrCloseShow() {
      this.iframeShow = false;
      this.stopBodyScroll(false);
      document.getElementById("nb_icon_wrap").style.setProperty("display", "block");
    },
    stopBodyScroll(isFixed) {
      if (isFixed) {
        this.scrollY = window.scrollY;
        this.bodyStyle = document.body.style;

        document.body.style = `position:fixed;top:-${this.scrollY}px;height: 100%;width:100%;overflow: hidden;`;
      } else {
        document.body.style = this.bodyStyle;

        window.scrollTo(0, this.scrollY);
      }
    },

    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      // 更新——滚动前，滚动条距文档顶部的距离
      this.oldScrollTop = scrollTop;
      if (scrollStep < 0) {
        this.iframeWidth = window.innerWidth + "px" || "100vw";
        this.iframeHeight = window.innerHeight + "px" || "100vh";
      } else {
        this.iframeHeight =
          document.documentElement.clientHeight == 0
            ? document.body.clientHeight + "px"
            : document.documentElement.clientHeight + "px";
        this.iframeWidth =
          document.documentElement.clientWidth == 0
            ? document.body.clientWidth + "px"
            : document.documentElement.clientWidth + "px";
      }
    },
    // 打开弹窗
    popupShow() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false);
      this.dialogShow = true;
    },
    //关闭弹窗
    hide() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
      this.dialogShow = false;
    },
    // 确认新增
    submitInsert() {
      const that = this;
      var condition = this.insertForm;
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(condition.user_tel)) {
        this.$message.error("手机号码有误！");
        return;
      }
      if (
        condition.user_email != "" &&
        !/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
          condition.user_email
        )
      ) {
        this.$message.error("邮箱信息有误！");
        return;
      }
      if (condition.user_name == "") {
        this.$message.error("信息不完善！");
        return;
      }
      that.$axios
        .post("/board/insertClue", condition)
        .then((res) => {
          if (res.data) {
            that.$message({
              message: "提交成功！VR经理稍后与您联系",
              type: "success",
            });

            var mo = function (e) {
              e.preventDefault();
            };
            document.body.style.overflow = ""; //出现滚动条
            document.removeEventListener("touchmove", mo, false);
            that.dialogShow = false;
            that.insertForm = {
              user_name: "",
              user_tel: "",
              user_grade: 0,
              user_address: "",
              user_desc: "",
              user_company: "",
              user_email: "",
            };
          } else {
            that.$message.error("提交失败！请重试");
          }
        })
        .catch((err) => {
          that.$error({
            title: err.response.data.error,
          });
        });
    },
    // 跳转
    navTo(url, id, flag) {
      if (flag) {
        if (this.$route.name == id) return;
        this.$router.push({
          path: id,
        });
      } else {
        this.$router.push({
          path: url,
          query: { id: id, random: Math.random().toFixed(3) },
        });
      }
    },
  },
  components: {
    "v-footer-mobile": footer_mobile,
    "v-header": header,
    board_mobile: board_mobile,
    popup,
  },
};
</script>

<style scoped>
.home {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.home_banner {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  background-image: url("http://new.513dvr.com/gcweb/home_banner.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.banner_video {
  height: 100vh;
  object-fit: fill;
  top: 0;
  left: 0;
  position: relative;
}

.home_banner-text {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 3;
  color: #ffffff;
  align-items: center;
  flex-direction: column;
  font-family: Source Han Sans CN;
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.2);
}

.home_banner-title {
  font-size: 60px;
  font-weight: bold;
  letter-spacing: 5px;
}

.home_banner-desc {
  font-size: 18px;
  font-weight: 300;
}

.home_banner-desc:last-of-type {
  font-size: 24px;
  font-weight: 300;
  letter-spacing: 5px;
}

.home_banner-btn {
  position: absolute;
  bottom: 10%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home_banner-btn > button {
  width: 160px;
  height: 48px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
  color: #fff;
  letter-spacing: 3px;
  line-height: 48px;
  margin: 0 20px;
  cursor: pointer;
  margin-top: 20px;
}

button:focus {
  border: 0 none;
  outline: none;
}

.home_content-title {
  margin-bottom: 30px;
}

.home_content-title > .title_big {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

.home_content-title > .title_small {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: #999999;
}

.home_product-item-1 {
  position: relative;
  width: 100%;
  height: 335px;
  font-family: Source Han Sans SC;
  color: #ffffff;
}

.home_product-item-2 {
  position: relative;
  width: 100%;
  height: 160px;
  margin-top: 15px;
  font-family: Source Han Sans SC;
  color: #ffffff;
}

.case-item-pic > img,
.product-item-pic > img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.case-item-pic,
.product-item-pic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.product-item-font-big {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 47px;
  left: 20px;
  font-size: 18px;
  font-weight: bold;
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product-item-font-small {
  position: absolute;
  width: calc(100% - 80px);
  left: 20px;
  bottom: 22px;
  font-size: 14px;
  z-index: 4;
}

.item-hint {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 3;
}

.home_case-item-1 {
  position: relative;
  width: 100%;
  height: 190px;
  font-family: Source Han Sans SC;
  color: #ffffff;
}

.case-item-font-big {
  position: absolute;
  width: 78px;
  bottom: 80px;
  left: calc((100% - 68px) / 2);
  font-size: 18px;
  font-weight: bold;
  z-index: 4;
}

.case-item-arrow {
  position: absolute;
  width: 16px;
  bottom: 35px;
  left: calc((100% - 16px) / 2);
  z-index: 4;
}

.home_case-small {
  margin-top: 15px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.home_case-item-2 {
  position: relative;
  width: calc((100% - 15px) / 2);
  height: 190px;
  font-family: Source Han Sans SC;
  color: #ffffff;
  margin-bottom: 15px;
}

.home_issue-item {
  overflow: hidden;
  margin-bottom: 2px;
}

.unactive {
  height: 75px;
}

.active {
  height: auto;
}

.home_issue-item.active > .home_issue-title {
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
}

.home_issue-item.active > .home_issue-answer {
  border: 1px solid #1e7ff6;
}

.home_issue-item.active > .home_issue-title > .question-item-num,
.home_issue-item.active > .home_issue-title > .question-item-text {
  color: #ffffff;
}

.home_issue-item.active > .home_issue-title > .question-item-arrow > div {
  transform: rotate(225deg);
  border-right: 2px solid #1e7ff6;
  border-bottom: 2px solid #1e7ff6;
}

.home_issue-title {
  width: calc(100% - 30px);
  height: 75px;
  background: #f8f8f8;
  font-family: Source Han Sans SC;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  transition: all 0.5s;
}

.question-item-num {
  font-size: 26px;
  font-weight: bold;
  color: #cccccc;
  transition: all 0.5s;
}

.question-item-text {
  width: 209px;
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 21px;
  transition: all 0.5s;
}

.question-item-arrow {
  width: 24px;
  height: 24px;
  background: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s;
}

.question-item-arrow > div {
  width: 6px;
  height: 6px;
  border-right: 2px solid #cccccc;
  border-bottom: 2px solid #cccccc;
  transform: rotate(45deg);
  transition: all 0.5s;
}

.home_issue-answer {
  padding: 10px 27px;
  box-sizing: border-box;
}

.home_issue-answer-text {
  white-space: pre-wrap;
  margin-top: 20px;
  color: #333333;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
}

.popup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 100;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.board-form {
  width: 100vw;
  height: 80vh;
  background: #f8f8f8;
}

.popup-form {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}

.el-form-item {
  margin-bottom: 10px;
}

.popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  background-image: url("http://new.513dvr.com/gcweb/contact-header.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.popup-title > p {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.popup-iframe {
  overflow: auto;
}

.popup-iframe.safari {
  height: calc(100vh - 75px);
}

.popup-btn-hide {
  width: 15px;
  height: 4px;
  background-color: #fff;
}

.popup-btn {
  padding: 20px;
  box-sizing: border-box;
}

.popup-btn > p {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}

.popup-btn-submit {
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  color: #fff;
  font-family: Source Han Sans CN;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 10px;
}

.required {
  color: red;
  margin-right: 15px;
  font-size: 20px;
}
</style>
