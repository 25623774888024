<template>
  <div class="ourselves" id="ourselves">
    <v-header :activeId="5"></v-header>
    <boxOne :title="boxOne_title" :content="content" :bg="bg"></boxOne>
    <section class="box box2">
      <div class="widthBox">
        <div class="box_title">我们是谁？</div>
        <div class="box_firm">
          <img
            :src="imgUrl + 'LOGO-gc-2.png'"
            alt="顾诚智能科技"
            class="box_firm_img"
          />
          <span class="box_firm_text">广东顾诚智能科技有限公司，</span>
          <span class="box_firm_creatTime fontSamll"
            >成立于2018年12月18日。</span
          >
        </div>
        <div class="box_content_box fontSamll">
          致力于通过VR和3D重建等前沿创新技术,我们的设想是为公众还原一个真实可交互的实景空间，让信息的传递更便捷有效沉浸式体验让用户所见即所得，打破时间和空间的限制，全面提升用户的视觉及交互体验。
        </div>
        <div class="box_footer">
          <span class="fontSamll">我们的信念是: </span>
          <span>以客户为中心，全力以赴！</span>
        </div>
      </div>
    </section>
    <section
      :style="{ background: 'url(' + imgUrl + 'aboutus-bg-1.jpg' }"
      class="box box3"
    >
      <div class="widthBox">
        <div class="box3_title">我们在做什么？</div>
        <section class="box_paragraph box_paragraph1">
          <p class="box_paragraph_item">
            我们致力于通过VR和3D重建等前沿创新技术，为公众还原一个真实可交互的实景空间，
          </p>
          <p class="box_paragraph_item">
            让信息的传递更便捷有效，沉浸式体验让用户所见即所得，打破时间和空间的限制，全面提升用户的视觉及交互体验。
          </p>
        </section>
        <div class="segmentation"></div>
        <section class="box_paragraph box_paragraph2">
          <p class="box_paragraph_item">
            我们力求精致到每一个步骤，让每一个客户满意；
          </p>
          <p class="box_paragraph_item">
            我们为各行各业提供<span style="font-weight: bold"
              >定制化解决方案</span
            >，运用专业激光扫描设备拍摄，全自动化数字建模，给用户带来一流体验，
          </p>
          <p class="box_paragraph_item">
            用于：VR实景，VR实物建模，无人机航拍，VR / AR应用开发......
          </p>
        </section>
      </div>
    </section>
    <section class="banner bannerBg4">
      <div class="responsive">
        <div class="banner_box widthBox">
          <div class="evenMore_title">我们一直在努力</div>
          <ul class="evenMore_list">
            <li
              class="evenMore_item"
              v-for="(item, index) in evenMoreList"
              :key="index"
            >
              <div class="evenMore_item_title">{{ item.title }}</div>
              <div class="evenMore_item_num">{{ item.num }}</div>
              <div class="evenMore_item_content">{{ item.content }}</div>
            </li>
          </ul>
        </div>
      </div>
    </section>
    <partner :title="title" :content1="content1" :content2="content2"></partner>
    <section class="box box6">
      <div class="widthBox box6_main">
        <div>我们已经准备好了，随时为您提供最专业的服务和支持！</div>
      </div>
    </section>
    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import footer from "../../components/footer";
import partner from "../../components/partner";
import boxOne from "../../components/boxOne";
import backTop from "../../components/backTop";
import board from "../../components/board";

export default {
  name: "ourselves",
  data() {
    return {
      boardShow: false,
      imgUrl: "",
      evenMoreList: [
        {
          title: "服务行业领域",
          num: "9+",
          content:
            "顾诚已为旅游酒店、企业服务、信息通信、商业地产、公用事业等超过9大行业提供VR三维实景重建服务。",
        },
        {
          title: "累计服务客户",
          num: "10000+",
          content:
            "累计服务合作伙伴多达100+，其中包括知名企业60+，国外高端客户30+。",
        },
        {
          title: "客户满意度",
          num: "99.9%",
          content:
            "服务客户涉及各行各业，为企业提供一站式解决方案，达成客户满意度99.9%的成就。",
        },
      ],
      title: "合作伙伴",
      content2: "目前顾诚合作品牌及战略合作伙伴已多达100+，客户满意度高达99.9%",
      content1:
        "顾诚已为工厂建筑、旅游酒店、企业服务、商业地产、公用事业等多个行业提供VR三维实景重建服务",
      boxOne_title: "关于我们",
      content: "顾诚智能 —— 三维可视化服务专业提供商",
      bg: "banner-aboutus.jpg",
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
  },
  components: {
    "v-header": header,
    "v-footer": footer,
    partner: partner,
    boxOne: boxOne,
    backTop: backTop,
    board: board,
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
  },
};
</script>

<style scoped>
.box2 {
  text-align: center;
  margin-top: 6rem;
  font-size: 1.1rem;
}
.box2 .box_title {
  font-size: 2.4rem;
  font-weight: bold;
}
.box2 .box_firm_img {
  margin-right: 0.5rem;
}
.box2 .box_firmInfo {
  margin: 3rem 0;
}
.box2 .box_firm {
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.box2 .fontSamll {
  font-size: 0.8rem;
  color: #666666;
}
.box2 .box_content_box {
  line-height: 2rem;
  text-align: left;
  width: 50rem;
  margin: 0 auto;
}
.box2 .box_footer {
  margin-top: 3rem;
}
.box3 {
  /*background: url("../../assets/aboutus-bg-1.jpg") no-repeat;*/
  height: 36rem;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 7rem;
}
.box3 .box_paragraph1 {
  margin-top: 4rem;
}
.box3 .box_paragraph {
  line-height: 2rem;
}
.box3 .segmentation {
  width: 25rem;
  height: 1rem;
  border-bottom: 1px dashed #fff;
  margin: 2rem auto;
}
.box3 .box3_title {
  font-size: 2.4rem;
  font-weight: bold;
}
.bannerBg4 {
  background: #f8f8f8;
  height: 40rem;
  position: relative;
  margin-bottom: 8rem;
}
.bannerBg4 .evenMore_title {
  padding-top: 8rem;
  font-size: 2rem;
  text-align: center;
}
.bannerBg4 .evenMore_list {
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
}
.bannerBg4 .evenMore_list .evenMore_item_num {
  font-size: 2.4rem;
  margin-top: 2rem;
  font-family: KaigenSansTC;
  /*background: linear-gradient(to right, #30c0fc, #1e7ff6);*/
  font-weight: bold;
  color: transparent;
}
.bannerBg4 .evenMore_item:nth-child(1) .evenMore_item_num {
  background: linear-gradient(to right, #30c0fc, #1e7ff6);
  -webkit-background-clip: text;
}
.bannerBg4 .evenMore_item:nth-child(2) .evenMore_item_num {
  background: linear-gradient(to right, #fa5146, #ff9519);
  -webkit-background-clip: text;
}
.bannerBg4 .evenMore_item:nth-child(3) .evenMore_item_num {
  background: linear-gradient(to right, #30c0fc, #1e7ff6);
  -webkit-background-clip: text;
}
.bannerBg4 .evenMore_item_content {
  padding-top: 3rem;
  color: #666666;
  line-height: 2rem;
  font-size: 0.9rem;
  width: 20rem;
}
.box6 {
  height: 15rem;
  background-color: #f8f8f8;
}
.box6 .box6_main {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  height: 100%;
}
</style>
