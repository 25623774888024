<template>
  <div class="callMe" id="callMe">
    <v-header :activeId="5"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <form-submit :titleIsShow="titleIsShow"></form-submit>

    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import formSubmit from "../../components/formSubmit";
import footer from "../../components/footer";
import boxOne from "../../components/boxOne";
import backTop from "../../components/backTop";
import board from "../../components/board";

export default {
  name: "callMe",
  imgUrl: "",
  data() {
    return {
      title: "VR视觉新时代已来，您还止步不前吗？",
      content: "我们已经准备好了，随时为您提供最专业的服务和支持",
      bg: "banner-callMe.jpg",
      titleIsShow: 0,
      boardShow: false,
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
  },
  components: {
    "v-header": header,
    "form-submit": formSubmit,
    "v-footer": footer,
    boxOne: boxOne,
    backTop: backTop,
    board: board,
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
  },
};
</script>

<style scoped></style>
