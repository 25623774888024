<template>
  <div class="product">
    <v-header :activeId="1"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <section class="product_content">
      <section>
        <div class="product_content-preface widthBox">
          <p class="preface-left">“</p>
          <p class="preface-center">
            我们致力于通过VR和3D重建等前沿创新技术，为公众还原一个真实可交互的实景空间，让信息的传递更便捷有效，沉浸式体验让用户所见即所得，打破时间和空间的限制，全面提升用户的视觉及交互体验。
          </p>
          <p class="preface-right">”</p>
        </div>
      </section>
      <section class="product_content-centre widthBox" id="centre">
        <div class="product_content-title">
          <p class="title_big">产品中心</p>
          <p class="title_small">
            主营产品分为VR实景空间扫描和3D物品模型两大类
          </p>
        </div>
        <div class="centre-tabBar">
          <div
            class="centre-tabBar-sliding"
            :class="centreIndex == 1 ? 'sliding-left' : 'sliding-right'"
          ></div>
          <p
            class="centre-tabBar-item"
            :class="{ 'centre-active': centreIndex == 1 }"
            @click="centreCheck(1)"
          >
            VR实景空间
          </p>
          <p
            class="centre-tabBar-item"
            :class="{ 'centre-active': centreIndex == 2 }"
            @click="centreCheck(2)"
          >
            3D模型展示
          </p>
        </div>
        <div class="centre-iframe widthBox">
          <iframe
            width="95%"
            height="90%"
            :src="centreIframe"
            frameborder="0"
          ></iframe>
        </div>
      </section>
      <section class="product_content-kernel" id="kernel">
        <section class="kernel-item-fir widthBox">
          <div class="kernel-item-fir-left">
            <p class="kernel-item-fir-title">核心技术</p>
            <p class="kernel-item-fir-big">自动化三维数字建模</p>
            <p class="kernel-item-fir-desc">
              机器视觉+SLAM算法 <br />
              将采集的数据上传至云端服务器计算，实现自动化照片建模<br />
              图像分割+匹配，在线还原复刻实景，完成三维数字克隆
            </p>
          </div>
          <img
            class="kernel-item-fir-right"
            :src="imgUrl + 'product-tec.jpg'"
            alt="VR全景"
          />
        </section>
        <ul class="kernel-item-sec widthBox">
          <li class="kernel-item-sec-item">
            <img :src="imgUrl + 'product-tec-icon1.png'" />
            <div class="kernel-item-sec-item-text">
              <p>机器视觉</p>
              <p>
                利用光学原理，通过高精度相机+深度相机+结构光对环境进行精准测距，同时采集图像信息和三维点云数据。
              </p>
            </div>
          </li>
          <li class="kernel-item-sec-item">
            <img :src="imgUrl + 'product-tec-icon2.png'" />
            <div class="kernel-item-sec-item-text">
              <p>SLAM算法</p>
              <p>
                精准感知空间，识别环境，自动获取空间坐标定位，即时完成匹配构图。
              </p>
            </div>
          </li>
        </ul>
        <section class="kernel-item-third widthBox" id="facility">
          <img
            class="kernel-item-third-left"
            alt="VR全景"
            :src="imgUrl + 'product-equ.png'"
          />
          <div class="kernel-item-third-right">
            <p class="kernel-item-third-big">自适配多端设备</p>
            <p class="kernel-item-third-desc">
              无需下载软件和APP，一键分享链接，浏览器快速打开体验<br />
              4K高清画质效果，让你如同身临其境<br /><br />
              手机，IPAD，电脑，VR，AR设备<br />
              可根据不同设备分辨率自适配
            </p>
          </div>
        </section>
      </section>
      <section class="product_content-selfdom" id="selfdom">
        <div class="product_content-title">
          <p class="title_big">实现个性化多功能定制</p>
          <p class="title_small">
            我们为不同的行业需求提供个性化多功能定制服务
          </p>
        </div>
        <ul class="selfdom-list widthBox">
          <li
            class="selfdom-list-item"
            v-for="(item, index) in selfdomList"
            :key="index"
          >
            <div
              class="item-title"
              :style="{ backgroundImage: 'url(' + imgUrl + item.src }"
            >
              <p>{{ item.title }}</p>
              <div class="item-title-hint"></div>
            </div>
            <div class="item-content">
              <div class="item-content-label"></div>
              <div class="item-content-desc">
                <p>{{ item.title1 }}</p>
                <p>{{ item.desc1 }}</p>
              </div>
            </div>
            <div class="item-content">
              <div class="item-content-label"></div>
              <div class="item-content-desc">
                <p>{{ item.title2 }}</p>
                <p>{{ item.desc2 }}</p>
              </div>
            </div>
            <div class="item-content">
              <div class="item-content-label"></div>
              <div class="item-content-desc">
                <p>{{ item.title3 }}</p>
                <p>{{ item.desc3 }}</p>
              </div>
            </div>
          </li>
        </ul>
      </section>
    </section>
    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import footer from "../../components/footer";
import backTop from "../../components/backTop";
import boxOne from "../../components/boxOne";
import board from "../../components/board";

export default {
  name: "product",
  components: {
    "v-header": header,
    "v-footer": footer,
    backTop: backTop,
    boxOne: boxOne,
    board: board,
  },
  data() {
    return {
      boardShow: false,
      title: "产品介绍 与 VR实景技术",
      content: "专注3DVR实景技术服务，提升用户的视觉和交互体验，为您营销增值",
      bg: "banner-product.jpg",
      imgUrl: "",
      // 产品中心选择
      centreIndex: 1,
      centreIframe: "https://my.513dvr.com/view/LjMqvQ2xHfj.html",
      selfdomList: [
        {
          title: "标准定制功能",
          title1: "VR模型",
          desc1:
            "包括三维场景模型、3D漫游、上帝视角、平面图、场景切换、播放导览功能",
          title2: "标签热点功能",
          desc2: "可提供文字、图片、视频、语音等植入VR空间，丰富空间信息",
          title3: "增值服务功能",
          desc3:
            "自定义空间背景音乐，添加欢迎页、小地图、LOGO、快照、联系等功能",
          src: "product-function-header1.png",
        },
        {
          title: "大数据定制功能",
          title1: "大数据统计",
          desc1:
            "获取访客地点、访问次数、停留时长、浏览角度及坐标、转发分享等记录",
          title2: "验证访问功能",
          desc2: "提供空间密码加密或登记联系信息验证访问服务功能",
          title3: "上传产品 添加分类",
          desc3: "为企业量身打造，上传产品、添加分类、快速导览、查看产品详情",
          src: "product-function-header2.png",
        },
        {
          title: "更多定制功能",
          title1: "自定义标签",
          desc1: "提供标签图标自定义更换，打造个性化风格",
          title2: "微官网",
          desc2: "提供各类微网站模板，分享更容易，为您营销增值",
          title3: "更多需求定制",
          desc3: "根据您的需求，可提供个性化功能开发服务",
          src: "product-function-header3.png",
        },
      ],
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    if (this.$route.query.id) {
      this.$nextTick(() => {
        setTimeout(() => {
          document
            .getElementById(this.$route.query.id)
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$route.query.flag && (this.centreIndex = this.$route.query.flag);
        }, 200);
      });
    }
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
    // 产品中心选择
    centreCheck(val) {
      this.centreIndex = val;
      this.centreIframe =
        val == 1
          ? "https://my.513dvr.com/view/LjMqvQ2xHfj.html"
          : "http://model.513dvr.com/ws3d/sj001/1009/web/";
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.product_content {
  padding: 100px 0;
  box-sizing: border-box;
}

.product_content-preface {
  height: 120px;
  background: #f8f8f8;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.preface-left,
.preface-right {
  height: 50px;
  font-size: 60px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #cccccc;
}

.preface-left {
  margin-right: 30px;
}

.preface-right {
  margin-top: 32px;
  margin-left: 10px;
}

.preface-center {
  width: 799px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  line-height: 32px;
}

.product_content-title {
  margin-bottom: 40px;
}

.product_content-title > .title_big {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 1rem;
}

.product_content-title > .title_small {
  margin: 1rem 0;
  text-align: center;
  position: relative;
  color: #999999;
}

.centre-tabBar {
  width: 440px;
  margin: 59px auto;
  height: 55px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 28px;
  box-sizing: border-box;
}

.centre-tabBar-item {
  width: 50%;
  height: 100%;
  line-height: 55px;
  background: #ffffff;
  text-align: center;
  z-index: 3;
  background: transparent;
  color: #666666;
  cursor: pointer;
}

.centre-active {
  color: #ffffff;
  font-weight: bold;
}

.centre-tabBar-sliding {
  position: absolute;
  top: 0;
  width: 50%;
  height: 55px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 28px;
  transition: all 0.5s;
}

.sliding-left {
  left: 0;
}

.sliding-right {
  left: 220px;
}

.centre-iframe {
  height: 660px;
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/product-show.png")
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_content-kernel {
  padding-top: 80px;
  box-sizing: border-box;
}

.kernel-item-fir,
.kernel-item-third {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;
}

.kernel-item-fir {
  height: 400px;
  margin-bottom: 80px;
}

.kernel-item-third {
  height: 600px;
  background: #f8f8f8;
  border-radius: 10px;
  margin-top: 80px;
}

.kernel-item-fir-left {
  width: calc(100% - 600px);
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: Source Han Sans CN;
}

.kernel-item-fir-right {
  width: 600px;
}

.kernel-item-third-left {
  width: 513px;
}

.kernel-item-fir-title {
  font-size: 32px;
  font-weight: bold;
  color: #000000;
}

.kernel-item-fir-big,
.kernel-item-third-big {
  font-size: 48px;
  font-weight: bold;
  color: #000000;
  background: linear-gradient(0deg, #30c0fc 0%, #1e7ff6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 40px;
}
.kernel-item-fir-big {
  margin-top: 80px;
}

.kernel-item-fir-desc,
.kernel-item-third-desc {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
}

.kernel-item-sec {
  display: flex;
  justify-content: space-around;
  padding: 0 10px;
  box-sizing: border-box;
}

.kernel-item-sec-item {
  display: flex;
}

.kernel-item-sec-item > img {
  width: 33px;
  height: 37px;
  margin-right: 30px;
}

.kernel-item-sec-item-text > p:first-of-type {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  margin: 10px 0;
}

.kernel-item-sec-item-text > p:last-of-type {
  width: 400px;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
}

.selfdom-list {
  display: flex;
  justify-content: space-between;
}

.selfdom-list-item {
  width: 32%;
  height: 536px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  border-radius: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.selfdom-list-item:hover {
  border-left: 1px solid #0083ff;
  border-right: 1px solid #0083ff;
  border-bottom: 1px solid #0083ff;
}

.item-title {
  width: 100%;
  height: 100px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 30px;
  box-sizing: border-box;
}

.item-title > p {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.item-title-hint {
  width: 48px;
  height: 4px;
  background: #ffffff;
  margin-top: 20px;
}

.item-content {
  height: 145px;
  border-top: 1px solid #eeeeee;
  padding: 20px 30px 0 30px;
  box-sizing: border-box;
  font-family: Source Han Sans CN;
  display: flex;
}

.item-content-label {
  width: 10px;
  height: 10px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 50%;
  margin-right: 20px;
  margin-top: 2px;
}

.item-content-desc {
  width: calc(100% - 10px);
}

.item-content-desc > p:first-of-type {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-bottom: 18px;
}

.item-content-desc > p:last-of-type {
  height: 39px;
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
}
</style>
