<template>
  <div class="home">
    <v-header :activeId="0" id="header_top"></v-header>
    <section class="home_content">
      <section class="home_content-video">
        <video
          loop="loop"
          ref="homeVideo"
          muted
          class="banner_video"
          autoplay="autoplay"
          :src="imgUrl + 'home-banner.mp4'"
        ></video>
        <div class="home_content-video-text">
          <p class="home_content-video-title">数字孪生·世界零距离</p>
          <p class="home_content-video-desc">
            3000元给你做3D实景网站
          </p>
          <div class="home_content-video-btn">
            <button
              class="btn1"
              @click="
                () => {
                  iframeUrl = 'https://my.513dvr.com/view/LjMqvQ2xHfj.html';
                  dialogVisible = true;
                }
              "
            >
              体验实景展厅
            </button>
            <button class="btn1" style="padding: 0 20px" @click="toGood">
              体验3D实景网站
            </button>
            <button class="btn2" @click="popupShow">预约演示</button>
          </div>
        </div>
        <el-dialog
          class="iframe-dialog"
          :visible.sync="dialogVisible"
          lock-scroll
          width="60%"
          top="0"
        >
          <iframe
            :src="iframeUrl"
            width="100%"
            height="100%"
            frameborder="0"
          ></iframe>
        </el-dialog>
      </section>
      <section class="home_content-project widthBox">
        <div class="home_content-title">
          <p class="title_big">产品中心</p>
          <p class="title_small">
            专注3DVR实景技术服务&nbsp;&nbsp;为您营销增值
            <router-link to="/product">查看全部></router-link>
          </p>
        </div>
        <div class="home_content-project-detail">
          <div
            class="project-item-1"
            :class="{ 'project-active': projectId == 1 }"
            @mouseover="projectItem(1, true)"
            @mouseout="projectItem(1, false)"
            @click="anchor('/product', 'centre', 1)"
          >
            <div class="project-item-pic">
              <img :src="imgUrl + 'index-product1.gif'" alt="VR实景空间" />
            </div>
            <div class="item-hint"></div>
            <p class="project-item-font-big">VR实景空间</p>
            <p class="project-item-font-small">
              <span>在线还原复刻实景，打造沉浸式漫游体验</span>
              <img :src="imgUrl + 'arrow-white.png'" />
            </p>
          </div>
          <div class="project-detail-right">
            <div
              class="project-item-2"
              :class="{ 'project-active': projectId == 2 }"
              @mouseover="projectItem(2, true)"
              @mouseout="projectItem(2, false)"
              @click="anchor('/product', 'centre', 2)"
            >
              <div class="project-item-pic">
                <img
                  :src="imgUrl + 'index-product2.jpg'"
                  alt="3D模型产品展示"
                />
              </div>
              <div class="item-hint"></div>
              <p class="project-item-font-big">3D模型产品展示</p>
              <p class="project-item-font-small">
                <span>720°全方位的产品展示 多形态一键看齐</span>
                <img :src="imgUrl + 'arrow-white.png'" />
              </p>
            </div>
            <div
              class="project-item-3"
              :class="{ 'project-active': projectId == 3 }"
              @mouseover="projectItem(3, true)"
              @mouseout="projectItem(3, false)"
              @click="anchor('/product', 'selfdom')"
            >
              <div class="project-item-pic">
                <img
                  :src="imgUrl + 'index-product3.jpg'"
                  alt="个性化功能定制"
                />
              </div>
              <div class="item-hint"></div>
              <p class="project-item-font-big">个性化功能定制</p>
              <p class="project-item-font-small">
                <span>自定义联系信息、大数据统计等多功能定制</span>
                <img :src="imgUrl + 'arrow-white.png'" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="home_content-case widthBox">
        <div class="home_content-title">
          <p class="title_big">行业案例</p>
          <p class="title_small">
            3D实景技术已在企业展厅、数字文博、民宿酒店等多个领域广泛应用
            <router-link to="/caseShow">查看全部></router-link>
          </p>
        </div>
        <div class="home_content-case-detail">
          <div class="case-detail-top">
            <div
              v-for="(item, index) in caseList"
              :key="index"
              :class="[item.className, { 'case-active': caseId == item.id }]"
              @mouseover="caseItem(item.id, true)"
              @mouseout="caseItem(item.id, false)"
              @click="anchor('caseShow', item.mid)"
            >
              <div class="case-item-pic">
                <img :src="imgUrl + item.picUrl" :alt="item.title" />
              </div>
              <div class="item-hint"></div>
              <p class="case-item-font-big">{{ item.title }}</p>
              <p class="case-item-font-small">{{ item.desc }}</p>
              <p class="case-item-font-arrow">
                <img :src="imgUrl + 'arrow-white.png'" />
              </p>
            </div>
          </div>
          <div class="case-detail-bottom">
            <div
              v-for="(item, index) in caseList2"
              :key="index"
              :class="[item.className, { 'case-active': caseId == item.id }]"
              @mouseover="caseItem(item.id, true)"
              @mouseout="caseItem(item.id, false)"
              @click="anchor('caseShow', item.mid)"
            >
              <div class="case-item-pic">
                <img :src="imgUrl + item.picUrl" :alt="item.title" />
              </div>
              <div class="item-hint"></div>
              <p class="case-item-font-big">{{ item.title }}</p>
              <p class="case-item-font-small">{{ item.desc }}</p>
              <p class="case-item-font-arrow">
                <img :src="imgUrl + 'arrow-white.png'" />
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="home_content-news widthBox">
        <div class="home_content-title">
          <p class="title_big">媒体资讯</p>
          <p class="title_small">
            了解企业资讯&nbsp;&nbsp;掌握最新动态
            <router-link to="/newsHome">查看全部></router-link>
          </p>
        </div>
        <div class="home_content-news-list">
          <a
            :href="'http://www.513dvr.com/news/' + item.id + '.html'"
            class="news-list-item"
            :class="{ one: index == 0 }"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <img
              class="news-list-item-img"
              v-if="index == 0"
              :src="item.src"
              :alt="item.seo_title"
            />
            <div class="news-font-title">
              <span>{{ item.title }}</span>
            </div>
            <div class="news-font-desc" v-if="index == 0">{{ item.desc }}</div>
            <div class="news-font-time">{{ item.create_time | format }}</div>
          </a>
        </div>
      </section>
      <section class="home_content-issue widthBox">
        <div class="home_content-title">
          <p class="title_big">常见问题</p>
          <p class="title_small">
            常见问题&nbsp;&nbsp;百问百答&nbsp;&nbsp;更多问题请联系咨询
          </p>
        </div>
        <div class="home_content-issue-detail">
          <ul class="issue-detail-question">
            <li
              class="question-item"
              :class="{ 'issue-active': issueIndex == index }"
              v-for="(item, index) in issueList"
              :key="index"
              @mouseover="issueClick(index, item.answer)"
              @mouseout="answerFlag = false"
            >
              <p class="question-item-num">{{ item.id }}</p>
              <p class="question-item-text">{{ item.text }}</p>
              <p class="question-item-arrow">
                <img
                  :src="
                    imgUrl +
                      (issueIndex == index
                        ? 'arrow-blue.png'
                        : 'arrow-grey.png')
                  "
                />
              </p>
            </li>
          </ul>
          <div class="issue-detail-answer">
            <p class="issue-detail-answer-title">百问百答</p>
            <p class="issue-detail-answer-dot">......</p>
            <p
              class="issue-detail-answer-text"
              :class="{ 'answer-active': answerFlag }"
            >
              {{ answer }}
            </p>
          </div>
        </div>
      </section>
    </section>
    <backTop
      class="home_backTop"
      :class="backTopActive"
      @switchBoard="switchBoard"
    ></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
    <v-footer></v-footer>
    <popup v-model="dialogShow" position="center" @input="hide">
      <div class="popup-title">
        <p>免费预约演示</p>
        <button class="popup-btn-hide" @click="hide">X</button>
      </div>
      <el-form
        class="popup-form"
        :inline="true"
        :model="insertForm"
        label-width="120px"
      >
        <el-form-item label="姓名" required>
          <el-input v-model="insertForm.user_name" placeholder="如何称呼您" />
        </el-form-item>
        <el-form-item label="联系方式" required>
          <el-input v-model="insertForm.user_tel" placeholder="您的手机号码" />
        </el-form-item>
        <el-form-item label="E-Mail">
          <el-input
            v-model="insertForm.user_email"
            placeholder="您的电子邮箱"
          />
        </el-form-item>
        <el-form-item label="所在地区">
          <el-input
            v-model="insertForm.user_address"
            placeholder="您所在的地区"
          />
        </el-form-item>
        <el-form-item class="popup-form-center" label="公司名称">
          <el-input
            class="popup-center"
            v-model="insertForm.user_company"
            placeholder="您的公司名称"
          />
        </el-form-item>
        <el-form-item class="popup-form-center" label="留言">
          <el-input
            type="textarea"
            class="popup-center"
            v-model="insertForm.user_desc"
            placeholder="您的留言"
          />
        </el-form-item>
      </el-form>
      <div class="popup-btn">
        <button class="popup-btn-submit" @click="submitInsert">提交申请</button>
        <p>
          我们保证您填写的信息不会泄露给第三方；提交申请后，我们会尽快与您联系！
        </p>
      </div>
    </popup>
  </div>
</template>

<script>
import header from "../../components/header";
import footer from "../../components/footer";
import backTop from "../../components/backTop";
import board from "../../components/board";
import utils from "../../components/utils";
import popup from "../../components/popup";

export default {
  name: "home",
  components: {
    "v-header": header,
    "v-footer": footer,
    backTop: backTop,
    board: board,
    popup: popup,
  },
  data() {
    return {
      imgUrl: "",
      titleIsShow: 1,
      // 侧边栏
      backTopActive: "",
      boardShow: false,
      // 弹窗
      dialogVisible: false,
      // 体验vr
      iframeUrl: "",
      // hover触发
      projectId: 0,
      caseId: 0,
      // 行业案例列表
      caseList: [
        {
          id: "1",
          picUrl: "index-case1.jpg",
          title: "企业展厅",
          desc: "协助企业内容升级，产品线上线下升级",
          mid: "vrExhibition",
          className: "case-item-1",
        },
        {
          id: "2",
          picUrl: "index-case2.jpg",
          title: "数字文博",
          desc: "VR云游博物馆，感受触手可及的文化",
          mid: "vrMuseum",
          className: "case-item-2",
        },
      ],
      caseList2: [
        {
          id: "3",
          picUrl: "index-case3.jpg",
          title: "民宿酒店",
          desc: "VR民宿看房，随时随地体验“虚拟入住”",
          mid: "vrHotel",
          className: "case-item-3",
        },
        {
          id: "4",
          picUrl: "index-case4.jpg",
          title: "VR看房",
          desc: "高效看房，身临其境，更懂地产的VR",
          mid: "vrHouse",
          className: "case-item-4",
        },
        {
          id: "5",
          picUrl: "index-case5.jpg",
          title: "更多行业",
          desc: "家居、学校、各类商铺、物品展示等更多行业",
          mid: "moreIndustry",
          className: "case-item-5",
        },
      ],
      // 新闻列表
      newsList: [],
      // 问题列表
      issueList: [
        {
          id: "01",
          text: "VR实景漫游和全景照片的区别是什么？",
          answer:
            "VR实景漫游是基于真实空间还原的空间三维模型，而全景照片是通过对不同角度和方位拍摄的照片进行拼接的二维照片。\n\n与全景照片相比，三维全景照片具有以下特点：\n1. 可以生成被扫描场景和空间的整体模型，并可以在网页中观看；\n2. 可以在空间中沉浸式漫游行走，而非全景照片不同点位之间的图像切换；\n3. 可以测量空间尺寸。",
        },
        {
          id: "02",
          text: "使用VR实景漫游，比起原来的照片和视频的好处是？",
          answer:
            "VR实景漫游是一种全新的浏览体验，比照片能提供更加真实的体验，沉浸式漫游如同身临其境，更加丰富的信息，比视频更加自由，看哪里点哪里。",
        },
        {
          id: "03",
          text: "顾诚提供的服务主要有什么？",
          answer:
            "顾诚智能自主研发VR和3D重建等技术以及云端快速自动化三维数字建模服务。同时，也可以为有空间还原建模需求的客户，提供一站式的上门服务。",
        },
        {
          id: "04",
          text: "顾诚的漫游空间可以带来哪些好处？",
          answer:
            "（1）可以全方位展示您的特色空间，方便您的目标客户在空间内自由行走。\n（2）可以升级您的营销方案，在空间内通过图文信息、视频信息、语音信息等与客户互动，并将场景信息展示给观众。\n（3）可以通过您的微信公众号、网站、朋友圈、APP快速分享空间，同时不需要安装任何插件或者应用。",
        },
        {
          id: "05",
          text: "3D实景是以什么文件交付给客户？",
          answer:
            "场景主要以一个链接的形式发送给您，您可以在网站中、微信号、APP中嵌入这个链接，将生成的场景实现远程分享。",
        },
        {
          id: "06",
          text: "扫描一个空间大约需要多久时间？",
          answer:
            "扫描拍摄的时间根据您需要拍摄的空间面积大小决定，一般来说，拍摄面积大小在300㎡以内的小空间，最快30分钟就可以完成扫描，完成扫描后需要根据您的需求进行建模，后期处理等工作。",
        },
      ],
      // 预约咨询
      insertForm: {
        user_name: "",
        user_tel: "",
        user_grade: 0,
        user_address: "",
        user_desc: "",
        user_company: "",
        user_email: "",
      },
      issueIndex: 0,
      answer:
        "VR实景漫游是基于真实空间还原的空间三维模型，而全景照片是通过对不同角度和方位拍摄的照片进行拼接的二维照片。\n\n与全景照片相比，三维全景照片具有以下特点：\n1. 可以生成被扫描场景和空间的整体模型，并可以在网页中观看；\n2. 可以在空间中沉浸式漫游行走，而非全景照片不同点位之间的图像切换；\n3. 可以测量空间尺寸。",
      dialogShow: false,
      answerFlag: false,
    };
  },
  mounted() {
    if (window.location.href.indexOf("callMe") > -1) {
      this.$router.push("callMe");
      return;
    }
    this.imgUrl = this.$imgUrl;
    window.addEventListener("scroll", this.getScroll);
    this.getList();
  },
  filters: {
    format(val) {
      return utils.format(val, "yyyy-MM-dd");
    },
  },
  methods: {
    // 监听滚动开启侧边栏
    getScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 100) {
        this.backTopActive = "backTopActive";
      } else {
        this.backTopActive = "";
      }
    },
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
    // 产品中心动画
    projectItem(id, val) {
      if (val) {
        this.projectId = id;
      } else {
        this.projectId = 0;
      }
    },
    // 行业案例动画
    caseItem(id, val) {
      if (val) {
        this.caseId = id;
      } else {
        this.caseId = 0;
      }
    },
    // 常见问题切换
    issueClick(index, val) {
      if (this.issueIndex == index) return;
      this.issueIndex = index;
      this.answer = val;
      this.answerFlag = true;
    },
    // 锚点
    anchor(url, val, flag) {
      this.$router.push({
        path: url,
        query: { id: val, flag: flag, random: Math.random().toFixed(3) },
      });
    },
    // 获取新闻列表
    getList() {
      this.$axios
        .post("/news/getNewsList", {
          pageInfo: {
            rows: 10,
            page: 1,
          },
          reqData: {
            create_time: "",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.newsList = res.data.news;
          }
        });
    },
    // 确认新增
    submitInsert() {
      const that = this;
      var condition = this.insertForm;
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(condition.user_tel)) {
        this.$message.error("手机号码有误！");
        return;
      }
      if (
        condition.user_email &&
        !/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
          condition.user_email
        )
      ) {
        this.$message.error("邮箱信息有误！");
        return;
      }
      if (condition.user_name == "") {
        this.$message.error("信息不完善！");
        return;
      }
      that.$axios
        .post("/board/insertClue", condition)
        .then((res) => {
          if (res.data) {
            that.$message({
              message: "提交成功！VR经理稍后与您联系",
              type: "success",
            });

            var mo = function(e) {
              e.preventDefault();
            };
            document.body.style.overflow = ""; //出现滚动条
            document.removeEventListener("touchmove", mo, false);
            that.dialogShow = false;
            that.insertForm = {
              user_name: "",
              user_tel: "",
              user_grade: 0,
              user_address: "",
              user_desc: "",
              user_company: "",
              user_email: "",
            };
          } else {
            that.$message.error("提交失败！请重试");
          }
        })
        .catch((err) => {
          that.$error({
            title: err.response.data.error,
          });
        });
    },
    // 打开弹窗
    popupShow() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false);
      this.dialogShow = true;
    },
    //关闭弹窗
    hide() {
      var mo = function(e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
      this.dialogShow = false;
    },
    // 前往good2011
    toGood() {
      window.open("http://www.good2011.com");
    },
  },
};
</script>

<style scoped>
.home {
  overflow-x: hidden;
}

.home_backTop {
  -webkit-animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.backTopActive {
  -webkit-animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.banner_video {
  width: 100vw;
  height: 100vh;
  object-fit: fill;
  top: 0;
  left: 0;
  z-index: 2;
  position: relative;
}

.home_content-video-text {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  z-index: 3;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: Source Han Sans CN;
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.3);
}

.home_content-video-title {
  font-size: 70px;
  font-weight: bold;
  margin-top: 15%;
  -webkit-animation: tracking-in-contract-bck-top 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck-top 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
}

.home_content-video-desc {
  font-size: 22px;
  font-weight: bold;
  margin-top: 40px;
  letter-spacing: 10px;
  -webkit-animation: tracking-in-contract-bck-bottom 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
  animation: tracking-in-contract-bck-bottom 1s
    cubic-bezier(0.215, 0.61, 0.355, 1) both;
}

.home_content-video-btn {
  position: absolute;
  bottom: 20%;
  height: 50px;
  width: 600px;
  display: flex;
  justify-content: space-between;
}

.home_content-video-btn > button {
  min-width: 160px;
  height: 48px;
  font-size: 18px;
  background-color: transparent;
  opacity: 0.6;
  color: #fff;
  letter-spacing: 3px;
  border-radius: 4px;
  line-height: 48px;
  cursor: pointer;
  transition: all 0.5s;
  box-sizing: border-box;
}

.home_content-video-btn > button:nth-child(1) {
  -webkit-animation: slide-in-fwd-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: slide-in-fwd-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  left: 0;
}

.home_content-video-btn > button:nth-child(2) {
  -webkit-animation: slide-in-fwd-bottom 1.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-bottom 1.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  left: calc((100% - 160px) / 2);
}

.home_content-video-btn > button:nth-child(3) {
  -webkit-animation: slide-in-fwd-bottom 1.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-fwd-bottom 1.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  right: 0;
}

.btn1:hover {
  border: 2px solid #0bc6f5;
  min-width: 180px;
  height: 51px;
}

.btn2:hover {
  min-width: 180px;
  height: 51px;
}

.btn1 {
  border: 1px solid #0bc6f5;
}

.btn2 {
  background: linear-gradient(90deg, #0aecfa 0, #0d6eeb 100%);
}

.popup-title {
  min-width: 800px;
  height: 150px;
  background-image: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/contact-header.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 40px;
}

.popup-btn > p {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #cccccc;
  margin-top: 20px;
}

.popup-btn-submit {
  width: 240px;
  height: 48px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
}

.popup-title > p {
  font-size: 24px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.popup-form {
  padding: 40px 20px;
  display: flex;
  flex-wrap: wrap;
}

.popup-form .el-form-item {
  margin: 10px 0;
  width: 50%;
}

.popup-form .popup-form-center {
  width: 100%;
}

.popup-form .popup-center {
  width: 570px;
}

.popup-btn-hide {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 30px;
  line-height: 30px;
  text-align: center;
  height: 30px;
  border-radius: 50%;
  font-size: 20px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.home_content-title {
  margin-bottom: 40px;
}

.home_content-title > .title_big {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 1rem;
}

.home_content-title > .title_small {
  margin: 1rem 0;
  text-align: center;
  position: relative;
  color: #999999;
}

.title_small > a {
  color: #999999;
  font-weight: 400;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.home_content-project-detail {
  display: flex;
  align-items: center;
}

.project-detail-right {
  height: 555px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}

.project-item-1 {
  position: relative;
  width: 555px;
  height: 555px;
  cursor: pointer;
}
.project-item-2,
.project-item-3 {
  position: relative;
  width: 555px;
  height: 262.5px;
  cursor: pointer;
}

.project-item-font-big {
  position: absolute;
  bottom: 40px;
  left: 40px;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  z-index: 4;
  -webkit-animation: project-big-in-top 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: project-big-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.project-item-font-small {
  position: absolute;
  width: calc(100% - 80px);
  left: 40px;
  font-size: 14px;
  font-family: Source Han Sans SC;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  z-index: 4;
  -webkit-animation: project-small-in-top 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: project-small-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.item-hint {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 3;
  opacity: 0;
  transition: opacity 0.5s;
}

.project-active > .item-hint,
.case-active > .item-hint,
.case-active > .case-item-font-arrow {
  opacity: 0.99;
}

.project-active > .project-item-font-big {
  -webkit-animation: project-big-in-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: project-big-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.project-active > .project-item-font-small {
  -webkit-animation: project-small-in-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: project-small-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
}

.case-detail-top,
.case-detail-bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.case-item-1 {
  margin-right: 30px;
}
.case-item-1,
.case-item-2 {
  position: relative;
  width: 555px;
  height: 320px;
  cursor: pointer;
}
.case-item-3,
.case-item-5,
.case-item-4 {
  position: relative;
  width: 360px;
  height: 420px;
  cursor: pointer;
}
.case-item-4 {
  margin: 0 30px;
}

.case-item-font-big {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 24px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #ffffff;
  z-index: 4;
  -webkit-animation: case-big-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: case-big-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.case-item-font-small {
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
  font-size: 14px;
  font-family: Source Han Sans SC;
  color: #ffffff;
  opacity: 0;
  z-index: 4;
  -webkit-animation: case-small-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: case-small-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.case-item-font-arrow {
  position: absolute;
  bottom: 20px;
  width: 100%;
  text-align: center;
  opacity: 0;
}

.case-item-pic > img,
.project-item-pic > img {
  object-fit: fill;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.case-item-pic,
.project-item-pic {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.case-active .case-item-pic > img,
.project-active .project-item-pic > img {
  transform: scale(1.1);
  -ms-transform: scale(1.1); /* IE 9 */
  -moz-transform: scale(1.1); /* Firefox */
  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
  -o-transform: scale(1.1);
}

.case-active > .case-item-font-big {
  -webkit-animation: case-big-in-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: case-big-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.case-active > .case-item-font-small {
  -webkit-animation: case-small-in-bottom 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: case-small-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.news-list-item {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  padding-bottom: 1rem;
  display: flex;
  cursor: pointer;
  position: relative;
  justify-content: space-between;
  font-weight: normal;
}

.news-list-item.one > .news-font-title {
  position: absolute;
  left: 300px;
  top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.news-list-item.one > .news-font-desc {
  position: absolute;
  left: 300px;
  top: 50px;
  width: 550px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.news-list-item.one > .news-font-time {
  position: absolute;
  left: 300px;
  top: 110px;
}

.news-font-title:hover {
  color: #1e7ef6;
}

.news-list-item-img {
  width: 280px;
  height: 150px;
}

.news-font-title {
  font-size: 16px;
  font-family: Source Han Sans SC;
  color: #000000;
}

.news-font-time {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #999999;
}

.home_content-issue {
  padding-bottom: 60px;
  box-sizing: border-box;
}

.home_content-issue-detail {
  height: 450px;
  width: 100%;
  background: #f8f8f8;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
}

.issue-detail-question {
  width: 400px;
  height: 400px;
  overflow: auto;
}

.question-item {
  width: 100%;
  height: 100px;
  color: #cccccc;
  background-color: #fff;
  font-family: Source Han Sans SC;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: all 1s;
}

.question-item-num {
  font-size: 32px;
  font-weight: bold;
}

.question-item-text {
  width: 250px;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.question-item-arrow {
  width: 32px;
  height: 32px;
  background: #ffffff;
  border-radius: 50%;
  text-align: center;
  line-height: 32px;
}

.issue-active {
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  color: #fff;
}

.issue-active .question-item-arrow {
  animation: opacityShow 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes opacityShow {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes opacityShow {
  0% {
    transform: scale(0.6);
  }

  100% {
    transform: scale(1);
  }
}

.issue-detail-answer {
  width: calc(100% - 430px);
  margin-left: 30px;
  height: 400px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
}

.issue-detail-answer-title {
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: 800;
  font-style: italic;
  color: #000000;
  background: linear-gradient(0deg, #30c0fc 0%, #1e7ff6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.issue-detail-answer-dot {
  color: #30c0fc;
  font-size: 30px;
}

.issue-detail-answer-text {
  white-space: pre-wrap;
  margin-top: 20px;
  color: #333333;
  line-height: 32px;
}

.iframe-dialog >>> .el-dialog {
  background: transparent;
  height: 100%;
  margin: 0 auto;
  box-shadow: none;
}

.iframe-dialog >>> .el-dialog__body {
  padding: 0;
  height: 100%;
  padding: 5% 0;
  box-sizing: border-box;
}

.iframe-dialog >>> .el-dialog__header {
  display: none;
}

.iframe-dialog {
  text-align: center;
}
</style>
