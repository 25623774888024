<template>
  <div class="error">
    <img :src="imgUrl + '404-error.png'" alt="顾诚智能" />
    <router-link class="back" to="/">返回首页</router-link>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgUrl: "",
    };
  },

  mounted() {
    this.imgUrl = this.$imgUrl;
  },
};
</script>

<style>
.error {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
}
.error img {
  width: 650px;
  margin-bottom: 40px;
}

.back {
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  transition: all 0.2s;
  background-color: #30c0fc;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  border-radius: 5px;
  opacity: 0.8;
}
</style>
