<template>
  <div class="servicePlan">
    <v-header></v-header>
    <section
      class="service_banner"
      :style="{ backgroundImage: 'url(' + imgUrl + 'server-banner-mobile.jpg)' }"
    >
      <div class="service_banner-title flex">
        <p class="title_big">服务方案</p>
        <p class="title_small">一站式量身定制服务方案</p>
      </div>
    </section>
    <ul class="service-navBar flex">
      <li
        :class="{ active: navIndex == index }"
        v-for="(item, index) in navBar"
        :key="index"
        @click="scrollTo(index, item.url)"
      >
        {{ item.title }}
      </li>
    </ul>
    <section class="widthBox_mobile">
      <div class="service" id="service">
        <div class="service_content-title">
          <p class="title_big">服务流程</p>
          <p class="title_small">精致到每一个步骤，让每一个客户满意</p>
        </div>
        <ul class="service_flow">
          <li class="service_flow-item" v-for="(item, index) in serviceList" :key="index">
            <div class="service_flow-item-left">
              <img :src="imgUrl + item.src" alt="" />
              <p>{{ item.name }}</p>
              <p>{{ item.desc }}</p>
            </div>
            <img v-if="item.arrow" :src="imgUrl + item.arrow" alt="" />
          </li>
        </ul>
      </div>
      <div class="offerPlan" id="offerPlan">
        <div class="service_content-title">
          <p class="title_big">报价方案</p>
          <p class="title_small">我们提供多种套餐，满足不同行业的需求</p>
        </div>
        <ul class="offerPlan_plan">
          <li class="offerPlan_plan-item item1">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>A</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;基础版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ aPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>面积：</span>
              <div class="plan_area">
                <p :class="{ active: aIndex == 1 }" @click="planArea('a', 1, 9800)">
                  300㎡
                </p>
                <p :class="{ active: aIndex == 2 }" @click="planArea('a', 2, 16800)">
                  600㎡
                </p>
                <p :class="{ active: aIndex == 3 }" @click="planArea('a', 3, 25800)">
                  1000㎡
                </p>
              </div>
            </div>
          </li>
          <li class="offerPlan_plan-item item2">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>B</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;标准版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ bPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>面积：</span>
              <div class="plan_area">
                <p :class="{ active: bIndex == 1 }" @click="planArea('b', 1, 15800)">
                  300㎡
                </p>
                <p :class="{ active: bIndex == 2 }" @click="planArea('b', 2, 29800)">
                  600㎡
                </p>
                <p :class="{ active: bIndex == 3 }" @click="planArea('b', 3, 46800)">
                  1000㎡
                </p>
              </div>
            </div>
          </li>
          <li class="offerPlan_plan-item item3">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>C</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;升级版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ cPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>面积：</span>
              <div class="plan_area">
                <p :class="{ active: cIndex == 1 }" @click="planArea('c', 1, 18800)">
                  300㎡
                </p>
                <p :class="{ active: cIndex == 2 }" @click="planArea('c', 2, 35800)">
                  600㎡
                </p>
                <p :class="{ active: cIndex == 3 }" @click="planArea('c', 3, 56800)">
                  1000㎡
                </p>
              </div>
            </div>
          </li>
        </ul>
        <img class="offerPlan_content" :src="imgUrl + 'service-plan.png'" alt="" />
      </div>
    </section>
    <v-footer-mobile></v-footer-mobile>
    <board_mobile></board_mobile>
  </div>
</template>

<script>
import header from "../../components/header";
import footer_mobile from "../../components/footer_mobile";
import board_mobile from "../../components/board_mobile";

export default {
  name: "servicePlan",
  components: {
    "v-header": header,
    "v-footer-mobile": footer_mobile,
    board_mobile: board_mobile,
  },
  data() {
    return {
      title: "服务方案",
      content: "一站式量身定制服务方案，有效助力企业营销推广",
      bg: "服务方案/banner-service.jpg",
      imgUrl: "",
      serviceList: [
        {
          src: "service-step-1.png",
          name: "需求沟通",
          desc: "明确需求 签订合同",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-2.png",
          name: "扫描拍摄",
          desc: "最快30分钟 极速拍摄",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-3.png",
          name: "模型制作",
          desc: "三维建模 云端处理",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-4.png",
          name: "个性化功能",
          desc: "多功能定制处理",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-6.png",
          name: "验收交付",
          desc: "链接交付展示",
          arrow: "",
        },
      ],
      navBar: [
        { title: "服务流程", url: "service" },
        { title: "报价方案", url: "offerPlan" },
      ],
      navIndex: 0,
      // 套餐
      aPrice: 9800,
      bPrice: 15800,
      cPrice: 18800,
      aIndex: 1,
      bIndex: 1,
      cIndex: 1,
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;

    if (this.$route.query.id) {
      this.$nextTick(() => {
        setTimeout(() => {
          document
            .getElementById(this.$route.query.id)
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$route.query.flag && (this.centreIndex = this.$route.query.flag);
        }, 200);
      });
    }
  },
  methods: {
    planArea(type, index, price) {
      switch (type) {
        case "a":
          this.aIndex = index;
          this.aPrice = price;
          break;
        case "b":
          this.bIndex = index;
          this.bPrice = price;
          break;
        case "c":
          this.cIndex = index;
          this.cPrice = price;
          break;
      }
    },
    // 锚点
    scrollTo(index, val) {
      this.navIndex = index;
      document.getElementById(val).scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.product {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.service_banner {
  width: 100vw;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.service_banner-title {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.service_banner-title > .title_big {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.service_banner-title > .title_small {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.service_content-title {
  margin-bottom: 30px;
}

.service_content-title > .title_big {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

.service_content-title > .title_small {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: #999999;
}

.service-navBar {
  overflow-x: scroll;
  height: 44px;
  background: #f8f8f8;
  justify-content: center;
}

.service-navBar > .active {
  font-weight: bold;
}

.service-navBar > li {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.service_flow {
  margin-top: 2rem;
  width: 100%;
  height: 13rem;
  background: url("http://new.513dvr.com/gcweb/service-step-phone.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.service_flow-item {
  display: flex;
  color: #ffffff;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50%;
  padding: 0 2rem;
  flex-shrink: 0;
  list-style: none;
  white-space: nowrap;
}

.service_flow-item > img {
  width: 35px;
  height: 20px;
  position: absolute;
  right: -17.5px;
}

.service_flow-item-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service_flow-item-left > img {
  margin-bottom: 0.8rem;
}

.service_flow-item-left > p {
  color: #cccccc;
  font-size: 12px;
}

.service_flow-item-left > p:first-of-type {
  margin-bottom: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
}

.offerPlan_plan {
  width: 100%;
  height: 140px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 20px 0;
  overflow-y: hidden;
  flex-wrap: nowrap;
}

.offerPlan_plan-item {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
  margin-right: 0.8rem;
}

.item1 {
  background: url("http://new.513dvr.com/gcweb/A.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.item2 {
  background: url("http://new.513dvr.com/gcweb/B.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.item3 {
  background: url("http://new.513dvr.com/gcweb/C.png") no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.offerPlan_plan-item-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.offerPlan_plan-item-bottom {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan_font-name {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.plan_font-name > span {
  font-size: 36px;
  font-style: italic;
  margin-right: 5px;
}

.plan_font-price {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.plan_font-price > span {
  font-size: 16px;
}

.offerPlan_plan-item-bottom > span {
  font-size: 16px;
  color: #ffffff;
  width: 3rem;
}

.plan_area {
  width: 211px;
  border: 1px solid rgb(255, 255, 255, 0.5);
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.plan_area > p {
  width: 33.3333%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
  cursor: pointer;
}

.plan_area > .active {
  background-color: rgb(255, 255, 255, 0.5);
}

.offerPlan_content {
  width: 100%;
}
</style>
