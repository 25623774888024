<template>
  <div class="backTop_board-form" :class="{ active: !boardShow }">
    <div class="board-title">
      <p>留言咨询</p>
      <div class="border-title-colse" @click="colse">
        <p></p>
      </div>
    </div>
    <el-form :model="insertForm">
      <el-form-item class="form-required">
        <el-input v-model="insertForm.user_name" placeholder="如何称呼您" />
        <p class="required">*</p>
      </el-form-item>
      <el-form-item class="form-required">
        <el-input
          v-model="insertForm.user_tel"
          placeholder="您的手机号码"
          maxlength="11"
        />
        <p class="required">*</p>
      </el-form-item>
      <el-form-item>
        <el-input v-model="insertForm.user_email" placeholder="您的电子邮箱" />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="insertForm.user_address"
          placeholder="您的地区位置"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="insertForm.user_company"
          placeholder="您的公司名称"
        />
      </el-form-item>
      <el-form-item>
        <el-input
          v-model="insertForm.user_desc"
          type="textarea"
          placeholder="您的留言"
        />
      </el-form-item>
    </el-form>
    <div class="board-footer">
      <button class="board-btn" @click="submitInsert">提交</button>
    </div>
  </div>
</template>
<script>
export default {
  name: "board",
  props: ["boardShow"],
  data() {
    return {
      showFlag: false,
      boardActive: "boardActive",
      insertForm: {
        user_name: "",
        user_tel: "",
        user_grade: 0,
        user_address: "",
        user_desc: "",
        user_company: "",
        user_email: "",
      },
    };
  },
  methods: {
    show() {
      this.showFlag = !this.showFlag;
    },
    // 确认新增
    submitInsert() {
      const that = this;
      var condition = this.insertForm;
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(condition.user_tel)) {
        this.$message.error("手机号码有误！");
        return;
      }
      if (
        condition.user_email &&
        !/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
          condition.user_email
        )
      ) {
        this.$message.error("邮箱信息有误！");
        return;
      }
      if (condition.user_name == "") {
        this.$message.error("信息不完善！");
        return;
      }
      that.$axios
        .post("/board/insertClue", condition)
        .then((res) => {
          if (res.data) {
            that.$message({
              message: "提交成功！VR经理稍后与您联系",
              type: "success",
            });
            that.insertForm = {
              user_name: "",
              user_tel: "",
              user_grade: 0,
              user_address: "",
              user_desc: "",
              user_company: "",
              user_email: "",
            };
          } else {
            that.$message.error("提交失败！请重试");
          }
        })
        .catch((err) => {
          that.$error({
            title: err.response.data.error,
          });
        });
    },
    // 关闭留言板
    colse() {
      this.$emit("switchBoard", false);
    },
  },
};
</script>

<style scoped>
.backTop_board-form {
  height: 410px;
  width: 260px;
  position: fixed;
  left: 0;
  bottom: 18px;
  z-index: 999;
  border-radius: 10px;
}

.backTop_board-form.active {
  display: none;
}

.board-title {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 0 20px;
  color: #fff;
  font-size: 20px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.border-title-colse {
  width: 15px;
  height: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.border-title-colse > p {
  height: 3px;
  width: 15px;
  background-color: #fff;
}

.el-input,
.el-textarea {
  width: 90%;
}

.el-form-item {
  margin-bottom: 10px;
}

.el-form-item >>> .el-form-item__content {
  text-align: center;
}

.el-form {
  padding: 5px;
  width: 260px;
  background: #f8f8f8;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
}

.board-footer {
  background: #f8f8f8;
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
}

.board-btn {
  width: 80px;
  height: 32px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  text-align: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}

.form-required {
  position: relative;
}

.required {
  position: absolute;
  color: red;
  right: 30px;
  font-size: 16px;
  bottom: 0px;
}
</style>
