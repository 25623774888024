<template>
  <div class="caseShow">
    <v-header></v-header>
    <section
      class="caseShow_banner"
      :style="{ backgroundImage: 'url(' + imgUrl + 'caseShow/banner-case.jpg)' }"
    >
      <div class="caseShow_banner-title flex">
        <p class="title_big">产品介绍</p>
        <p class="title_small">专注3DVR实景技术服务</p>
      </div>
    </section>
    <ul class="caseShow-navBar flex">
      <li
        :class="{ active: navIndex == index }"
        v-for="(item, index) in navBar"
        :key="index"
        @click="scrollTo(index, item.url)"
      >
        {{ item.title }}
      </li>
    </ul>
    <section
      class="caseShow_item widthBox_mobile"
      :class="{ active: !item.flag }"
      v-for="(item, index) in caseList"
      :key="index"
      :id="item.id"
    >
      <p class="caseShow_item-title">{{ item.titleNum }}&nbsp;&nbsp;{{ item.title }}</p>
      <div class="caseShow_item-hint">
        <div class="caseShow_item-hint-hint"></div>
      </div>
      <div class="caseShow_item-desc">
        <p>{{ item.text }}</p>
        <p>{{ item.desc }}</p>
      </div>
      <div
        class="caseShow_item-img"
        :style="{ backgroundImage: 'url(' + imgUrl + item.src }"
      >
        <p class="caseShow_item-btn" @click="toModel(item.modelUrl)">查看案例</p>
      </div>
      <div
        class="caseShow_item-text"
        v-for="(items, indexs) in item.ulList"
        :key="indexs"
      >
        <div class="caseShow_item-text-label"></div>
        <p>{{ items }}</p>
      </div>
    </section>
    <section class="caseShow_item widthBox_mobile" id="vrMore">
      <p class="caseShow_item-title">05&nbsp;&nbsp;更多行业</p>
      <div class="caseShow_item-hint">
        <div class="caseShow_item-hint-hint"></div>
      </div>
      <ul class="caseShow_item-bar">
        <div
          class="caseShow_item-bar-sliding"
          :style="{ left: slidingStyle + '%' }"
        ></div>
        <li
          v-for="item in slidingList"
          :key="item.left"
          @click="slidingClick(item.id, item.left)"
          :style="{ color: slidingStyle == item.left ? '#fff' : '' }"
        >
          {{ item.name }}
        </li>
      </ul>
      <el-carousel
        class="caseShow_item-banner"
        arrow="never"
        :autoplay="false"
        height="240px"
        indicator-position="none"
        ref="carousel"
      >
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
          <img :src="imgUrl + item" />
        </el-carousel-item>
        <p
          class="caseShow_item-banner-btn"
          :style="{ display: slidBtn ? 'block' : 'none' }"
          @click="toModel(slidUrl)"
        >
          查看案例
        </p>
      </el-carousel>
    </section>
    <v-footer-mobile></v-footer-mobile>
    <board_mobile></board_mobile>
  </div>
</template>

<script>
import header from "../../components/header";
import footer_mobile from "../../components/footer_mobile";
import board_mobile from "../../components/board_mobile";
export default {
  name: "caseShow_mobile",
  components: {
    "v-footer-mobile": footer_mobile,
    "v-header": header,
    board_mobile: board_mobile,
  },
  data() {
    return {
      imgUrl: "",
      // 导航栏
      navIndex: 0,
      navBar: [
        { title: "企业展厅", url: "vrExhibition" },
        { title: "数字文博", url: "vrMuseum" },
        { title: "民宿酒店", url: "vrHotel" },
        { title: "VR看房", url: "vrHouse" },
        { title: "更多", url: "vrMore" },
      ],
      caseList: [
        {
          title: "企业展厅",
          id: "vrExhibition",
          titleNum: "01",
          text: "协助企业内容升级，产品线上线下升级",
          desc: "痛点：照片、视频等二维信息难以全面传递企业信息",
          ulList: [
            "为外贸企业量身打造，海外客户足不出户就能通透验厂，促进成交机会",
            "随时随地看展厅、车间、仓库等，便捷获取产品信息及价格",
            "24小时永不落幕，颠覆图文形式，让客户过目不忘",
          ],
          src: "caseShow/01qiye.jpg",
          flag: true,
          modelUrl: "https://my.513dvr.com/view2/vr/goodZUgPCiC2UV5.html?m=ZUgPCiC2UV5",
        },
        {
          id: "vrMuseum",
          titleNum: "02",
          title: "数字文博",
          text: "VR云游博物馆，感受触手可及的文化",
          desc: "痛点：受时间和地域的限制，历史文化难以传播",
          ulList: [
            "结合图文视频、语音讲解等标签热点，丰富信息传递，文化传播效率更高",
            "文物数字化存档，达到了保护文物、传承历史的目的",
            "跨越时间和地域的限制，让传统文化在数字时代更加璀璨",
          ],
          src: "02wenbo.jpg",
          flag: false,
          modelUrl: "https://my.513dvr.com/view2/vr/fangzhiguan.html#1L",
        },
        {
          id: "vrHotel",
          titleNum: "03",
          title: "民宿酒店",
          text: "VR民宿看房，随时随地体验“虚拟入住”",
          desc: "痛点：用户在线上难以准确掌握民宿酒店的真实情况",
          ulList: [
            "增强用户体验感，让用户在预选环节时间延长",
            "场景真实克隆，所见即所得，解决信息不对称问题，提高用户满意度",
            "三维立体展示，民宿吸粉获客更容易",
          ],
          src: "03minsu.jpg",
          flag: true,
          modelUrl: "https://my.513dvr.com/LWaK3QfWyuY.html?m=LWaK3QfWyuY",
        },
        {
          id: "vrHouse",
          titleNum: "04",
          title: "VR看房",
          text: "高效看房，身临其境，更懂地产的VR",
          desc: "痛点：客户实地看房太麻烦？担心房源真实性？",
          ulList: [
            "打造最真实的线上看房体验，全新沉浸式体验开创消费新模式",
            "远程线上带看，无需排队，高效看房",
            "数据化标尺复刻真实空间，房源信息、户型、面积、方位朝向全面展示",
          ],
          src: "04kanfang.jpg",
          flag: false,
          modelUrl: "https://my.513dvr.com/view/n4rswHwttk5.html",
        },
      ],
      slidingList: [
        { id: 0, name: "家居", left: 0 },
        { id: 1, name: "学校", left: 20 },
        { id: 2, name: "餐厅", left: 40 },
        { id: 3, name: "健身房", left: 60 },
        { id: 4, name: "物体", left: 80 },
      ],
      bannerList: [
        "caseShow/05-1jiaju.jpg",
        "caseShow/05-2xuexiao.png",
        "caseShow/05-3canyin.jpg",
        "caseShow/05-4jianshenf.jpg",
        "caseShow/05-5wuti.jpg",
      ],
      // 滑块样式
      slidingStyle: 0,
      // 滑块选中id
      slidingIndex: 0,
      slidUrl: "",
      slidBtn: true,
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    if (this.$route.query.id) {
      this.$nextTick(() => {
        setTimeout(() => {
          document
            .getElementById(this.$route.query.id)
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$route.query.flag && (this.centreIndex = this.$route.query.flag);
        }, 200);
      });
    }
  },
  methods: {
    // 锚点
    scrollTo(index, val) {
      this.navIndex = index;
      document.getElementById(val).scrollIntoView({ block: "start", behavior: "smooth" });
    },
    // 前往模型
    toModel(val) {
      window.open(val, "_blank");
    },
    // 更多模型
    slidingClick(id, val) {
      const that = this;
      that.slidingStyle = val;
      that.slidingIndex = id;
      that.$refs.carousel.setActiveItem(id);
      that.slidBtn = false;
      setTimeout(() => {
        that.slidBtn = true;
      }, 500);
      switch (id) {
        case 0:
          that.slidUrl = "https://my.513dvr.com/view/SEfCG7npPjm.html";
          break;
        case 1:
          that.slidUrl = "https://720yun.com/t/dfvkOlih0iw?scene_id=51426579";
          break;
        case 2:
          that.slidUrl = "https://my.513dvr.com/pJtb3ZvYP4w.html?m=pJtb3ZvYP4w";
          break;
        case 3:
          that.slidUrl = "https://my.513dvr.com/peUB6Pooapf.html?m=peUB6Pooapf";
          break;
        case 4:
          that.slidUrl =
            "http://model.513dvr.com/ddsw/jq/webEdit/project/embedProject/Lj8fFEfc-kCzsq9AU-nJvp7RUn-9bcr9qgT.html?hideHead=1&hideLogo=1&hideShare=1";
          break;
      }
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.caseShow {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.caseShow_banner {
  width: 100vw;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.caseShow_banner-title {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.caseShow_banner-title > .title_big {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.caseShow_banner-title > .title_small {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.caseShow-navBar {
  overflow-x: scroll;
  height: 44px;
  background: #f8f8f8;
  justify-content: center;
  margin-bottom: 30px;
}

.caseShow-navBar > .active {
  font-weight: bold;
}

.caseShow-navBar > li {
  width: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.caseShow_item {
  background-color: #f8f8f8;
  padding: 60px 20px 20px 20px;
  box-sizing: border-box;
}

.caseShow_item.active {
  background-color: #fff;
}

.caseShow_item-title {
  width: 100%;
  font-size: 20px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-style: italic;
  color: #000000;
  text-align: center;
}

.caseShow_item-hint {
  width: calc(100% - 40px);
  margin: 20px;
  height: 2px;
  background: #eeeeee;
}

.caseShow_item-hint-hint {
  width: 30px;
  height: 3px;
  margin: 0 calc((100% - 30px) / 2);
  background: #000000;
}

.caseShow_item-text {
  position: relative;
  margin: 20px 0;
}

.caseShow_item-text-label {
  width: 8px;
  height: 8px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
}

.caseShow_item-text > p {
  margin-left: 25px;
  box-sizing: border-box;
  font-family: Source Han Sans CN;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  line-height: 23px;
}

.caseShow_item-desc > p:first-of-type {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  width: 100%;
  text-align: center;
}

.caseShow_item-desc > p:last-of-type {
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #999999;
  line-height: 21px;
  margin: 10px 0 20px 0;
}

.caseShow_item-img {
  position: relative;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 210px;
  margin: 0 auto;
}

.caseShow_item-btn,
.caseShow_item-banner-btn {
  position: absolute;
  left: calc((100% - 100px) / 2);
  bottom: calc((100% - 40px) / 2);
  width: 100px;
  height: 40px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 20px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  z-index: 3;
}

.caseShow_item-bar {
  position: relative;
  width: 95%;
  margin: 0 2.5%;
  height: 40px;
  background: #ffffff;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.caseShow_item-bar > li {
  width: 25%;
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  z-index: 3;
}

.caseShow_item-bar-sliding {
  position: absolute;
  top: 0;
  width: 20%;
  height: 40px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 20px;
  transition: all 0.5s;
}

.caseShow_item-banner {
  margin: 20px 0;
  position: relative;
}

.caseShow_item-banner img {
  width: 100%;
  height: 100%;
}
</style>
