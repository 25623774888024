<template>
  <div class="board">
    <div class="board-pic" :class="{ active: isShow }" @click="popupShow">
      <img :src="imgUrl + 'icon/phone-online-contact.png'" alt="" />
    </div>
    <popup v-model="dialogShow" position="bottom" @input="hide">
      <div class="board-form">
        <div class="popup-title">
          <div></div>
          <p>留言咨询</p>
          <p class="popup-btn-hide" @click="hide"></p>
        </div>
        <el-form class="popup-form" :model="insertForm">
          <el-form-item>
            <el-input
              prefix-icon="el-icon-user-solid"
              v-model="insertForm.user_name"
              placeholder="如何称呼您"
            >
              <p slot="suffix" class="required">*</p>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              prefix-icon="el-icon-phone"
              v-model="insertForm.user_tel"
              placeholder="您的手机号码"
            >
              <p slot="suffix" class="required">*</p>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-input v-model="insertForm.user_email" placeholder="您的电子邮箱" />
          </el-form-item>
          <el-form-item>
            <el-input v-model="insertForm.user_address" placeholder="您所在的地区" />
          </el-form-item>
          <el-form-item class="popup-form-center">
            <el-input
              class="popup-center"
              v-model="insertForm.user_company"
              placeholder="您的公司名称"
            />
          </el-form-item>
          <el-form-item class="popup-form-center">
            <el-input
              type="textarea"
              class="popup-center"
              v-model="insertForm.user_desc"
              placeholder="您的留言"
            />
          </el-form-item>
        </el-form>
        <div class="popup-btn">
          <p>我们保证您填写的信息不会泄露给第三方；提交申请后，我们会尽快与您联系！</p>
          <button class="popup-btn-submit" @click="submitInsert">提交申请</button>
        </div>
      </div>
    </popup>
  </div>
</template>

<script>
import popup from "./popup";
export default {
  data() {
    return {
      imgUrl: "",
      oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
      scrollTop: 0, // 记录当前的滚动距离
      isShow: true,
      // 预约咨询
      insertForm: {
        user_name: "",
        user_tel: "",
        user_grade: 0,
        user_address: "",
        user_desc: "",
        user_company: "",
        user_email: "",
      },
      dialogShow: false,
    };
  },
  components: {
    popup: popup,
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    this.handleScroll();
  },
  watch: {
    scrollTop(newValue, oldValue) {
      //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
      setTimeout(() => {
        if (newValue == window.scrollY) {
          //延时执行后当newValue等于window.scrollY，代表滚动结束
          //每次滚动结束后都要给oldScrollTop赋值
          this.oldScrollTop = newValue;
          this.isShow = true;
        }
      }, 500);
      if (this.oldScrollTop == oldValue) {
        //每次滚动开始时oldScrollTop与oldValue相等
        this.isShow = false;
      }
    },
  },
  methods: {
    handleScroll() {
      window.addEventListener("scroll", this.scroll);
    },
    scroll() {
      this.scrollTop = window.scrollY;
    },
    // 打开弹窗
    popupShow() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = "hidden";
      document.addEventListener("touchmove", mo, false);
      this.dialogShow = true;
    },
    //关闭弹窗
    hide() {
      var mo = function (e) {
        e.preventDefault();
      };
      document.body.style.overflow = ""; //出现滚动条
      document.removeEventListener("touchmove", mo, false);
      this.dialogShow = false;
    },
    // 确认新增
    submitInsert() {
      const that = this;
      var condition = this.insertForm;
      if (!/^1(3|4|5|6|7|8|9)\d{9}$/.test(condition.user_tel)) {
        this.$message.error("手机号码有误！");
        return;
      }
      if (
        !/^[A-Za-z0-9]+([_\.][A-Za-z0-9]+)*@([A-Za-z0-9\-]+\.)+[A-Za-z]{2,6}$/.test(
          condition.user_email
        )
      ) {
        this.$message.error("邮箱信息有误！");
        return;
      }
      if (condition.user_name == "") {
        this.$message.error("信息不完善！");
        return;
      }
      that.$axios
        .post("/board/insertClue", condition)
        .then((res) => {
          if (res.data) {
            that.$message({
              message: "提交成功！VR经理稍后与您联系",
              type: "success",
            });

            var mo = function (e) {
              e.preventDefault();
            };
            document.body.style.overflow = ""; //出现滚动条
            document.removeEventListener("touchmove", mo, false);
            that.dialogShow = false;
            that.insertForm = {
              user_name: "",
              user_tel: "",
              user_grade: 0,
              user_address: "",
              user_desc: "",
              user_company: "",
              user_email: "",
            };
          } else {
            that.$message.error("提交失败！请重试");
          }
        })
        .catch((err) => {
          that.$error({
            title: err.response.data.error,
          });
        });
    },
  },
  beforeDestroy() {
    //离开当前组件别忘记移除事件监听
    window.removeEventListener("scroll", this.scroll);
  },
};
</script>

<style scoped>
.board-pic {
  position: fixed;
  right: 0;
  bottom: 0.5rem;
  z-index: 5;
  -webkit-animation: board-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: board-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.board-pic > img {
  width: 80px;
}

.board-pic.active {
  -webkit-animation: board-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: board-left 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes board-left {
  0% {
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@keyframes board-left {
  0% {
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }

  100% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }
}

@-webkit-keyframes board-right {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }
}

@keyframes board-right {
  0% {
    -webkit-transform: translateX(0px);
    transform: translateX(0px);
  }

  100% {
    -webkit-transform: translateX(180px);
    transform: translateX(180px);
  }
}

.board-form {
  width: 100vw;
  height: 80vh;
  background: #f8f8f8;
}

.popup-form {
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
}

.el-form-item {
  margin-bottom: 10px;
}

.popup-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  height: 50px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
}

.popup-title > p {
  font-size: 18px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.popup-btn-hide {
  width: 15px;
  height: 4px;
  background-color: #fff;
}

.popup-btn {
  padding: 20px;
  box-sizing: border-box;
}

.popup-btn > p {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
}

.popup-btn-submit {
  width: 100%;
  height: 50px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  color: #fff;
  font-family: Source Han Sans CN;
  font-weight: bold;
  border-radius: 5px;
  margin-top: 10px;
}

.required {
  color: red;
  margin-right: 15px;
  font-size: 18px;
}
</style>
