<template>
  <div class="product">
    <v-header></v-header>
    <section
      class="product_banner"
      :style="{
        backgroundImage: 'url(' + imgUrl + 'product/banner-product.jpg)',
      }"
    >
      <div class="product_banner-title flex">
        <p class="title_big">产品介绍</p>
        <p class="title_small">专注3DVR实景技术服务</p>
      </div>
    </section>
    <ul class="product-navBar flex">
      <li
        :class="{ active: navIndex == index }"
        v-for="(item, index) in navBar"
        :key="index"
        @click="scrollTo(index, item.url)"
      >
        {{ item.title }}
      </li>
    </ul>
    <section class="product_centre widthBox_mobile" id="centre">
      <div class="product_content-title">
        <p class="title_big">产品中心</p>
        <p class="title_small">主营产品为VR实景空间扫描和3D物品模型</p>
      </div>
      <div class="centre-tabBar">
        <div
          class="centre-tabBar-sliding"
          :class="centreIndex == 1 ? 'sliding-left' : 'sliding-right'"
        ></div>
        <p
          class="centre-tabBar-item"
          :class="{ 'centre-active': centreIndex == 1 }"
          @click="centreCheck(1)"
        >
          VR实景空间
        </p>
        <p
          class="centre-tabBar-item"
          :class="{ 'centre-active': centreIndex == 2 }"
          @click="centreCheck(2)"
        >
          3D模型展示
        </p>
      </div>
      <div class="centre-iframe">
        <iframe
          width="95%"
          height="90%"
          :src="centreIframe"
          frameborder="0"
        ></iframe>
      </div>
    </section>
    <section class="product_kernel widthBox_mobile" id="kernel">
      <div class="product_content-title">
        <p class="title_big">核心技术</p>
        <p class="title_small colours">自动化三维数字建模</p>
      </div>

      <ul class="kernel-item-sec">
        <li>
          <p class="kernel-item-sec-title flex">
            <img :src="imgUrl + 'product-tec-icon1.png'" />机器视觉
          </p>
          <p class="kernel-item-sec-desc">
            利用光学原理，通过高精度相机+深度相机+结构光对环境进行精准测距，同时采集图像信息和三维点云数据。
          </p>
        </li>
        <li>
          <p class="kernel-item-sec-title flex">
            <img :src="imgUrl + 'product-tec-icon2.png'" />SLAM算法
          </p>
          <p class="kernel-item-sec-desc">
            精准感知空间，识别环境，自动获取空间坐标定位，即时完成匹配构图。
          </p>
        </li>
      </ul>
      <img
        class="kernel-item-img"
        :src="imgUrl + 'product/product-tec.jpg'"
        alt="VR全景"
      />
    </section>
    <section class="product_facility widthBox_mobile" id="facility">
      <div class="product_content-title" style="margin-bottom: 38px">
        <p class="title_small colours">自适配多端设备</p>
      </div>
      <img
        class="facility-item-img"
        :src="imgUrl + 'product/product-equ.png'"
        alt="VR全景"
      />
      <div class="facility-text">
        <div class="facility-text-label"></div>
        <p>无需下载软件和APP，一键分享链接，浏览器快速打开体验</p>
      </div>
      <div class="facility-text">
        <div class="facility-text-label"></div>
        <p>4K高清画质效果，让你如同身临其境</p>
      </div>
      <div class="facility-text">
        <div class="facility-text-label"></div>
        <p>手机，IPAD，电脑，VR，AR设备，可根据不同设备分辨率自适配</p>
      </div>
    </section>
    <section class="product_selfdom" id="selfdom">
      <div class="product_content-title">
        <p class="title_big">个性化多功能定制</p>
        <p class="title_small">我们为不同行业提供个性化多功能定制服务</p>
      </div>

      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="swiper-slide" :src="imgUrl + 'product/selfdom-1.png'"
        /></swiper-slide>
        <swiper-slide>
          <img class="swiper-slide" :src="imgUrl + 'product/selfdom-2.png'"
        /></swiper-slide>
        <swiper-slide>
          <img class="swiper-slide" :src="imgUrl + 'product/selfdom-3.png'"
        /></swiper-slide>
      </swiper>
    </section>
    <v-footer-mobile></v-footer-mobile>
    <board_mobile></board_mobile>
  </div>
</template>

<script>
import header from "../../components/header";
import footer_mobile from "../../components/footer_mobile";
import board_mobile from "../../components/board_mobile";
export default {
  name: "product_mobile",
  components: {
    "v-footer-mobile": footer_mobile,
    "v-header": header,
    board_mobile: board_mobile,
  },
  data() {
    return {
      imgUrl: "",
      // 导航栏
      navIndex: 0,
      // 产品中心选择
      centreIndex: 1,
      navBar: [
        { title: "产品中心", url: "centre" },
        { title: "核心技术", url: "kernel" },
        { title: "多设备适配", url: "facility" },
        { title: "多功能定制", url: "selfdom" },
      ],
      centreIframe: "https://my.513dvr.com/view/LjMqvQ2xHfj.html",
      swiperOptions: {
        loop: true, // 无限循环
        loopAdditionalSlides: 3,
        slidesPerView: "auto",
        autoplay: true,
        centeredSlides: true,
        effect: "coverflow",
        coverflowEffect: {
          rotate: 0, //slide做3d旋转时Y轴的旋转角度。默认50。
          stretch: -80, //每个slide之间的拉伸值，越大slide靠得越紧。 默认0。
          depth: 350, //slide的位置深度。值越大z轴距离越远，看起来越小。 默认100。
          modifier: 1, //depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显。默认1。
          slideShadows: false, //开启slide阴影。默认 true。
        },
      },
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    if (this.$route.query.id) {
      this.$nextTick(() => {
        setTimeout(() => {
          document
            .getElementById(this.$route.query.id)
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$route.query.flag && (this.centreIndex = this.$route.query.flag);
        }, 200);
      });
    }
  },
  methods: {
    // 产品中心选择
    centreCheck(val) {
      this.centreIndex = val;
      this.centreIframe =
        val == 1
          ? "https://my.513dvr.com/view/LjMqvQ2xHfj.html"
          : "http://model.513dvr.com/ws3d/sj001/1009/web/";
    },
    // 锚点
    scrollTo(index, val) {
      this.navIndex = index;
      document
        .getElementById(val)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>
<style scoped>
.product {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.product_banner {
  width: 100vw;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.product_banner-title {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.product_banner-title > .title_big {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.product_banner-title > .title_small {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.product_content-title {
  margin-bottom: 30px;
}

.product_content-title > .title_big {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

.product_content-title > .title_small {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: #999999;
}

.product_content-title > .title_small.colours {
  font-size: 30px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  background: linear-gradient(0deg, #30c0fc 0%, #1e7ff6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.product-navBar {
  overflow-x: scroll;
  height: 44px;
  background: #f8f8f8;
  justify-content: center;
}

.product-navBar > .active {
  font-weight: bold;
}

.product-navBar > li {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.centre-tabBar {
  width: 100%;
  margin: 20px auto;
  height: 55px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid #eeeeee;
  border-radius: 28px;
  box-sizing: border-box;
}

.centre-tabBar-item {
  width: 50%;
  height: 100%;
  line-height: 55px;
  background: #ffffff;
  text-align: center;
  z-index: 3;
  background: transparent;
  color: #666666;
  cursor: pointer;
}

.centre-active {
  color: #ffffff;
  font-weight: bold;
}

.centre-tabBar-sliding {
  position: absolute;
  top: 0;
  width: 50%;
  height: 55px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 28px;
  transition: all 0.5s;
}

.sliding-left {
  left: 0;
}

.sliding-right {
  left: 50%;
}

.centre-iframe {
  height: 345px;
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/product-show.png")
    no-repeat;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.kernel-item-sec > li {
  margin-bottom: 40px;
}

.kernel-item-sec-title {
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #000000;
  margin-bottom: 5px;
}

.kernel-item-sec-title > img {
  width: 24px;
  height: 26px;
  margin-right: 10px;
}

.kernel-item-sec-desc {
  font-size: 13px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-left: 34px;
}

.kernel-item-img,
.facility-item-img {
  width: 100%;
}

.product_kernel {
  margin-bottom: 60px;
}

.product_facility {
  background-color: #f8f8f8;
  padding: 40px 20px 20px 20px;
  box-sizing: border-box;
}

.facility-text {
  position: relative;
  margin: 20px 0;
}

.facility-text-label {
  width: 8px;
  height: 8px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 50%;
  position: absolute;
  top: 7px;
  left: 10px;
}

.facility-text > p {
  margin-left: 25px;
  box-sizing: border-box;
  font-family: Source Han Sans CN;
  line-height: 20px;
  font-size: 14px;
  color: #666666;
  line-height: 23px;
}

.swiper-slide {
  width: 250px;
}
</style>
