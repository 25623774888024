import Vue from 'vue';
import Router from 'vue-router';
import home from '../view/home/home';
import product from '../view/product/product';
const caseShow = () => import(/*webpackChunkName:"caseShow"*/ '@/view/caseShow/caseShow');
const servicePlan = () =>
  import(/*webpackChunkName:"servicePlan"*/ '@/view/servicePlan/servicePlan');
const newsHome = () => import(/*webpackChunkName:"newsHome"*/ '@/view/news/newsHome');
const newsDetails = () => import(/*webpackChunkName:"newsDetails"*/ '@/view/news/newsDetails');
const ourselves = () => import(/*webpackChunkName:"ourselves"*/ '@/view/ourselves/ourselves');
const error = () => import(/*webpackChunkName:"error"*/ '@/view/404');
const callMe = () => import(/*webpackChunkName:"callMe"*/ '@/view/callMe/callMe');
const home_mobile = () => import(/*webpackChunkName:"home_mobile"*/ '@/view/home/home_mobile');
const callMe_mobile = () =>
  import(/*webpackChunkName:"callMe_mobile"*/ '@/view/callMe/callMe_mobile');
const servicePlan_mobile = () =>
  import(/*webpackChunkName:"servicePlan_mobile"*/ '@/view/servicePlan/servicePlan_mobile');
const caseShow_mobile = () =>
  import(/*webpackChunkName:"caseShow_mobile"*/ '@/view/caseShow/caseShow_mobile');
const product_mobile = () =>
  import(/*webpackChunkName:"product_mobile"*/ '@/view/product/product_mobile');

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/home',
      redirect: '/',
    },
    {
      path: '/',
      name: 'home',
      component: home,
      meta: {
        isMobile: false,
        urlSwitch: '/home_mobile',
      },
    },
    {
      path: '/product',
      name: 'product',
      component: product,
      meta: {
        isMobile: false,
        urlSwitch: '/product_mobile',
      },
    },
    {
      path: '/caseShow',
      name: 'caseShow',
      component: caseShow,
      meta: {
        isMobile: false,
        urlSwitch: '/caseShow_mobile',
      },
    },
    {
      path: '/servicePlan',
      name: 'servicePlan',
      component: servicePlan,
      meta: {
        isMobile: false,
        urlSwitch: '/servicePlan_mobile',
      },
    },
    {
      path: '/newsHome',
      name: 'newsHome',
      component: newsHome,
      meta: {
        isMobile: false,
      },
    },
    {
      path: '/ourselves',
      name: 'ourselves',
      component: ourselves,
      meta: {
        isMobile: false,
        urlSwitch: '/ourselves_mobile',
      },
    },
    {
      path: '/callMe',
      name: 'callMe',
      component: callMe,
      meta: {
        isMobile: false,
        urlSwitch: '/callMe_mobile',
      },
    },
    {
      path: '/newsDetails',
      name: 'newsDetails',
      component: newsDetails,
      meta: {
        isMobile: false,
      },
    },
    {
      path: '/home_mobile',
      name: 'home_mobile',
      component: home_mobile,
      meta: {
        isMobile: true,
        urlSwitch: '/',
      },
    },
    {
      path: '/product_mobile',
      name: 'product_mobile',
      component: product_mobile,
      meta: {
        isMobile: true,
        urlSwitch: '/product',
      },
    },
    {
      path: '/caseShow_mobile',
      name: 'caseShow_mobile',
      component: caseShow_mobile,
      meta: {
        isMobile: true,
        urlSwitch: '/caseShow',
      },
    },
    {
      path: '/servicePlan_mobile',
      name: 'servicePlan_mobile',
      component: servicePlan_mobile,
      meta: {
        isMobile: true,
        urlSwitch: '/servicePlan',
      },
    },
    {
      path: '/callMe_mobile',
      name: 'callMe_mobile',
      component: callMe_mobile,
      meta: {
        isMobile: true,
        urlSwitch: '/callMe',
      },
    },
  ],
});
let flag = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);
router.beforeEach((to, form, next) => {
  if (to.matched.length === 0) next('/404');
  if (to.meta.isMobile) {
    if (flag) {
      next();
    } else {
      next(to.meta.urlSwitch);
    }
  } else {
    if (flag) {
      next(to.meta.urlSwitch);
    } else {
      next();
    }
  }
});
router.afterEach((to, form, next) => {
  window.scrollTo(0, 0);
});

export default router;
