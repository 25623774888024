<template>
  <div class="newsHome">
    <v-header :activeId="4"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <section class="box2 box">
      <div class="news_tabBar">
        <p :class="{ active: tabBarIndex == 0 }" @click="tabBarIndex = 0">
          新闻资讯
        </p>
        <p :class="{ active: tabBarIndex == 1 }" @click="tabBarIndex = 1">
          媒体资料库
        </p>
      </div>
      <div class="widthBox" v-if="tabBarIndex == 0">
        <div class="newsImgList">
          <a
            :href="'http://www.513dvr.com/news/' + item.id + '.html'"
            class="newsImgList_item"
            v-for="(item, index) in newsImgList"
            :key="index"
          >
            <el-row style="margin-bottom: 2rem">
              <el-col :span="9">
                <img :src="item.src" :alt="item.alt" class="newsImg" />
              </el-col>
              <el-col :span="15" class="newsImgList_main">
                <div class="newsImgList_title">{{ item.title }}</div>
                <div class="newsImgList_content">{{ item.desc }}</div>
                <div class="newsImgList_time">
                  {{ item.create_time | format }}
                </div>
              </el-col>
            </el-row>
          </a>
        </div>
        <div class="newsList">
          <a
            :href="'http://www.513dvr.com/news/' + item.id + '.html'"
            class="newsList_item"
            v-for="(item, index) in newsList"
            :key="index"
          >
            <div class="newsList_title">{{ item.title }}</div>
            <div class="newsList_time">{{ item.create_time | format }}</div>
          </a>
        </div>
        <div class="block pagination">
          <el-pagination
            layout="prev, pager, next"
            :total="total"
            :page-size="rows"
            prev-text="上一页"
            next-text="下一页"
            @prev-click="pagePrev"
            @next-click="pagenext"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
      <div class="widthBox" v-else>
        <div class="newsHome_content-title">
          <p class="title_big">视频资料区</p>
        </div>
        <div class="newsHome_content-video">
          <div
            class="newsHome_content-video-item"
            v-for="(item, index) in videoList"
            :key="index"
          >
            <div class="item-video" @click="dialogShow(item.videoUrl)">
              <img class="item-video-play" :src="imgUrl + 'play.png'" />
              <img
                class="item-video-pic"
                :src="imgUrl + item.picUrl"
                :alt="item.alt"
              />
            </div>
            <p>{{ item.title }}</p>
          </div>
        </div>
        <el-dialog
          class="video-dialog"
          :visible.sync="videoVisible"
          lock-scroll
          width="60%"
          top="5%"
        >
          <video :src="imgUrl + videoDialogSrc" autoplay controls></video>
        </el-dialog>
        <div class="newsHome_content-title">
          <p class="title_big">宣传图集区</p>
        </div>
        <div class="newsHome_content-picture">
          <div
            class="newsHome_content-picture-item"
            v-for="(item, index) in pictureList"
            :key="index"
          >
            <el-image
              class="item-picture"
              :src="imgUrl + item.picUrl"
              :alt="item.alt"
              :preview-src-list="item.bigUrl"
            >
            </el-image>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
    </section>
    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import boxOne from "../../components/boxOne";
import backTop from "../../components/backTop";
import footer from "../../components/footer";
import board from "../../components/board";
import utils from "../../components/utils";

export default {
  name: "newsHome",
  data() {
    return {
      imgUrl: "",
      title: "顾诚 x VR：新闻热点聚集地",
      content: "查看顾诚实时新闻动态，了解VR行业新闻热点",
      bg: "banner-news.jpg",
      newsList: [],
      newsImgList: [],
      page: 1,
      rows: 8,
      total: 0,
      // 栏目index
      tabBarIndex: 0,
      // 媒体资料库列表
      videoList: [
        {
          picUrl: "video1-pic.jpg",
          videoUrl: "video1.mp4",
          title: "顾诚VR产品功能演示",
          alt: "顾诚VR",
        },
        {
          picUrl: "video2-pic.jpg",
          videoUrl: "video2.mp4",
          title: "顾诚VR x 场景购",
          alt: "顾诚VR",
        },
        {
          picUrl: "video3-pic.jpg",
          videoUrl: "video3.mp4",
          title: "顾诚VR：助力第6届中国国际“互联网+”大学生创新创业大赛",
          alt: "顾诚VR",
        },
        {
          picUrl: "video4-pic.jpg",
          videoUrl: "video4.mp4",
          title: "VR新案例 | 钰乘礼品：个性化筛选功能",
          alt: "顾诚VR",
        },
        {
          picUrl: "video5-pic.jpg",
          videoUrl: "video5.mp4",
          title: "VR新案例 | 长虹集团：标签自定义",
          alt: "顾诚VR",
        },
        {
          picUrl: "video6-pic.jpg",
          videoUrl: "video6.mp4",
          title: "万物墙：产品展示 选3D模型",
          alt: "顾诚VR",
        },
      ],
      pictureList: [
        {
          picUrl: "picture1-pic.jpg",
          bigUrl: [
            "http://new.513dvr.com/gcweb/picture1.1.jpg",
            "http://new.513dvr.com/gcweb/picture1.2.jpg",
          ],
          title: "顾诚智能科技 电子宣传折页",
          alt: "顾诚VR",
        },
        {
          picUrl: "picture2-pic.jpg",
          bigUrl: ["http://new.513dvr.com/gcweb/picture2.jpg"],
          title: "关于顾诚智能科技",
          alt: "顾诚VR",
        },
        {
          picUrl: "picture3-pic.jpg",
          bigUrl: ["http://new.513dvr.com/gcweb/picture3.jpg"],
          title: "顾诚VR实景技术的九大功能",
          alt: "顾诚VR",
        },
        {
          picUrl: "picture4-pic.jpg",
          bigUrl: ["http://new.513dvr.com/gcweb/picture4.jpg"],
          title: "顾诚VR x 企业工厂",
          alt: "顾诚VR",
        },
        {
          picUrl: "picture5-pic.jpg",
          bigUrl: ["http://new.513dvr.com/gcweb/picture5.jpg"],
          title: "顾诚VR x 民宿选房",
          alt: "顾诚VR",
        },
        {
          picUrl: "picture6-pic.jpg",
          bigUrl: ["http://new.513dvr.com/gcweb/picture6.jpg"],
          title: "顾诚VR x 场景购",
          alt: "顾诚VR",
        },
      ],
      // video弹窗
      videoVisible: false,
      videoDialogSrc: "",
      // 长图弹窗
      bigPicVisible: false,
      bigPicDialogSrc: "",
      boardShow: false,
    };
  },
  filters: {
    format(val) {
      return utils.format(val, "yyyy-MM-dd");
    },
  },
  components: {
    "v-header": header,
    "v-footer": footer,
    boxOne: boxOne,
    backTop: backTop,
    board: board,
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    this.getList();
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
    pagePrev() {
      this.page--;
      this.getList();
    },
    pagenext() {
      this.page++;
      this.getList();
    },
    currentChange(e) {
      this.page = e;
      this.getList();
    },
    getList() {
      this.$axios
        .post("/news/getNewsList", {
          pageInfo: {
            rows: this.rows,
            page: this.page,
          },
          reqData: {
            create_time: "",
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let list = res.data.news;
            this.newsList = list.slice(2);
            this.newsImgList = list.slice(0, 2);
            this.total = res.data.total;
          }
        });
    },
    // video播放
    dialogShow(val) {
      this.videoVisible = true;
      this.videoDialogSrc = val;
    },
  },
};
</script>

<style scoped>
.box2 .newsImgList_item {
  margin-bottom: 2rem;
  cursor: pointer;
}
.box2 .newsImgList_title:hover {
  color: #1e7ef6;
}
.box2 .newsImgList_content:hover {
  color: #1e7ef6;
}
.box2 .newsImgList_time:hover {
  color: #1e7ef6;
}
.box2 .newsImgList {
  margin-top: 4rem;
}
.box2 .newsImgList_title {
  color: #333;
  font-weight: bold;
  font-size: 1.2rem;
}
.box2 .newsImgList_content {
  font-size: 0.9rem;
  line-height: 2rem;
  color: #666;
  font-weight: normal;
}
.box2 .newsImgList_time {
  font-size: 0.9rem;
  color: #666;
}
.box2 .newsImg {
  width: 23rem;
  height: 13rem;
}
.box2 .newsImgList_main {
  border-bottom: 1px solid #e8e8e8;
  /*padding: 0 2rem;*/
  height: 13rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem 0;
}
.box2 .newsList_item {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e8e8e8;
  color: #333;
  padding-bottom: 2rem;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  font-weight: normal;
}
.box2 .newsList_item:hover {
  color: #1e7ef6;
}
.pagination {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 3rem;
}

.news_tabBar {
  width: 100vw;
  height: 55px;
  background: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news_tabBar > p {
  width: 100px;
  height: 100%;
  text-align: center;
  line-height: 55px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  margin: 0 40px;
  cursor: pointer;
}

.news_tabBar > .active {
  color: #000;
  font-weight: bold;
}

.newsHome_content-title {
  margin-bottom: 40px;
}

.newsHome_content-title > .title_big {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.newsHome_content-video,
.newsHome_content-picture {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.newsHome_content-video-item,
.newsHome_content-picture-item {
  width: 32%;
  height: 281px;
  margin-bottom: 40px;
}

.newsHome_content-video-item > .item-video {
  position: relative;
  cursor: pointer;
}

.newsHome_content-video-item > .item-video > .item-video-pic,
.newsHome_content-picture-item >>> .el-image > img {
  width: 100%;
  height: 202.5px;
}

.newsHome_content-picture-item >>> .el-image > img {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  cursor: pointer;
}

.newsHome_content-picture-item >>> .el-image > img:hover {
  transform: scale(1.1);
  -ms-transform: scale(1.1); /* IE 9 */
  -moz-transform: scale(1.1); /* Firefox */
  -webkit-transform: scale(1.1); /* Safari 和 Chrome */
  -o-transform: scale(1.1);
}

.item-video-play {
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc((100% - 40px) / 2);
  left: calc((100% - 40px) / 2);
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
}

.newsHome_content-video-item > .item-video:hover > .item-video-play {
  transform: scale(1.5);
  -ms-transform: scale(1.5); /* IE 9 */
  -moz-transform: scale(1.5); /* Firefox */
  -webkit-transform: scale(1.5); /* Safari 和 Chrome */
  -o-transform: scale(1.5);
}

.newsHome_content-video-item > p,
.newsHome_content-picture-item > p {
  font-size: 16px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  line-height: 30px;
  margin: 20px 0;
  width: 100%;
  text-align: center;
}

.video-dialog >>> .el-dialog,
.bigPic-dialog >>> .el-dialog {
  background: transparent;
}

.video-dialog >>> .el-dialog__header,
.bigPic-dialog >>> .el-dialog__header {
  display: none;
}

.video-dialog,
.bigPic-dialog {
  text-align: center;
}

.video-dialog video {
  width: 100%;
}
</style>
