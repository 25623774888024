<template>
  <div class="box" :class="boxClass" :style="{ backgroundImage: 'url(' + imgUrl + bg }">
    <div class="widthBox box1_main" v-if="!isMobile">
      <div class="box1_title">
        {{ title }}
      </div>
      <div class="box1_content">{{ content }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "boxOne",
  data() {
    return {
      imgUrl: "",
      isMobile: "",
      boxClass: "box1",
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    this.isMobile = this.$flag;
    if (this.isMobile) {
      this.boxClass = "boxMobile";
    }
  },
  props: ["title", "content", "bg"],
};
</script>

<style scoped>
.box {
  width: 100%;
  display: flex;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}
.box1 {
  height: 30rem;
  color: #fff;
}
.boxMobile {
  color: #fff;
  height: 11rem;
}
.box1 .box1_main {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.box1 .box1_title {
  font-weight: bold;
  font-size: 2rem;
}
.box1 .box1_content {
  padding-top: 1rem;
}
</style>
