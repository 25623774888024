<template>
  <div id="header-top">
    <div class="header_main" v-if="headIsShow" :class="header_main">
      <el-row class="widthBox">
        <el-col :span="8" class="logo">
          <img
            :src="imgUrl + 'LOGO-gc-1.png'"
            style="width: 136px; height: 36px"
            @click="backHome"
            alt="顾诚智能"
          />
        </el-col>
        <el-col :span="16" class="headNav">
          <ul class="headNav_ul">
            <li
              class="headNav_item"
              :id="item.id"
              :class="item.active"
              v-for="(item, index) in navList"
              :key="index"
              @mouseover="tabShow = item.id"
              @mouseout="tabShow = null"
            >
              <a :href="'http://www.513dvr.com' + item.url">{{ item.name }}</a>
              <div class="headNav_item-pop" v-show="tabShow == item.id">
                <p
                  v-for="(items, idx) in item.children"
                  :key="idx"
                  @click.stop="anchor(item.url, items.id, items.url)"
                >
                  {{ items.childName }}
                </p>
              </div>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
    <div
      class="header_main header_main_mobile"
      :class="header_main_mobile"
      v-else
    >
      <el-row class="header_row_mobile">
        <el-col :span="12" class="logo">
          <img
            :src="imgUrl + 'LOGO-gc-1.png'"
            @click="backHome"
            alt="顾诚智能"
          />
        </el-col>
        <el-col :span="12" class="menu">
          <img
            :src="imgUrl + 'icon/menu-gray.png'"
            alt=""
            @click="switchMenu"
            class="menu_icon"
          />
        </el-col>
      </el-row>
    </div>
    <div class="mobile_menu_main" v-if="isMenu" @click="switchMenu">
      <div class="mobile_menu_main_box">
        <header class="mobile_menu_head">
          <i class="el-icon-close menuClose" @click.stop="switchMenu"></i>
        </header>
        <ul class="mobile_menu_list">
          <li
            class="mobile_menu_item"
            @click.stop="navTo(item.url)"
            :id="item.id"
            v-for="(item, index) in navList"
            :key="index"
          >
            {{ item.name }}
          </li>
        </ul>
        <footer class="mobile_menu_callMe">咨询联系：0769-88993721</footer>
        <footer class="mobile_menu_callMe">咨询联系：13922304039</footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTop",
  props: {
    activeId: Number,
  },
  data() {
    return {
      imgUrl: "",
      navList: [],
      active: "",
      header_main: "",
      header_main_mobile: "",
      headIsShow: 1,
      isMenu: 0,
      tabShow: null,
      oldScrollTop: 0,
    };
  },
  methods: {
    navTo(url) {
      let ifUrl = url.substring(1);
      this.isMenu = 0;
      if (this.$route.name == ifUrl) return;
      this.$router.push({
        path: url,
      });
    },
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    getScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // pc端
      if (scrollTop >= 100) {
        this.header_main = "header_main_scroll";
      } else {
        this.header_main = "";
      }
      // 移动端
      if (scrollTop >= 10) {
        this.header_main_mobile = "header_main_mobile_scroll";
        // // 滚动条滚动的距离
        // let scrollStep = scrollTop - this.oldScrollTop;
        // // 更新——滚动前，滚动条距文档顶部的距离
        // this.oldScrollTop = scrollTop;
        // if (scrollStep < 0) {
        //   console.log("滚动条向上滚动了！");
        // } else {
        //   console.log("滚动条向下滚动了！");
        // }
      } else {
        this.header_main_mobile = "";
      }
    },
    switchMenu() {
      this.isMenu = !this.isMenu;
    },
    backHome() {
      this.$router.push({
        path: "/",
      });
    },
    /**
     * 锚点
     * url为父地址
     * val为锚点id
     * childUrl为子地址
     */
    anchor(url, val, flag) {
      if (val == "callMe") {
        window.location.href = "http://513dvr.com?callMe";
        return;
      }
      if (flag) {
        if (this.$route.name == val) return;
        this.$router.push({
          path: val,
        });
      } else {
        this.$router.push({
          path: url,
          query: { id: val, random: Math.random().toFixed(3) },
        });
      }
    },
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    if (this._isMobile()) {
      this.headIsShow = 0;
      this.navList = [
        {
          name: "首页",
          id: 0,
          url: "/home_mobile",
        },
        {
          name: "产品介绍",
          id: 1,
          url: "/product_mobile",
        },
        {
          name: "行业解决方案",
          id: 2,
          url: "/caseShow_mobile",
        },
        {
          name: "服务方案",
          id: 3,
          url: "/servicePlan_mobile",
        },
        {
          name: "关于我们",
          id: 4,
          url: "/callMe_mobile",
        },
      ];
    } else {
      this.headIsShow = 1;
      this.navList = [
        {
          name: "首页",
          id: 0,
          url: "/",
        },
        {
          name: "产品介绍",
          id: 1,
          url: "/product",
          children: [
            { childName: "产品中心", url: false, id: "centre" },
            { childName: "核心技术", url: false, id: "kernel" },
            { childName: "多设备适配", url: false, id: "facility" },
            { childName: "多功能定制", url: false, id: "selfdom" },
          ],
        },
        {
          name: "行业解决方案",
          id: 2,
          url: "/caseShow",
          children: [
            { childName: "企业展厅", url: false, id: "vrExhibition" },
            { childName: "数字文博", url: false, id: "vrMuseum" },
            { childName: "民宿酒店", url: false, id: "vrHotel" },
            { childName: "VR看房", url: false, id: "vrHouse" },
            { childName: "更多行业", url: false, id: "moreIndustry" },
          ],
        },
        {
          name: "服务方案",
          id: 3,
          url: "/servicePlan",
          children: [
            { childName: "服务流程", url: false, id: "service" },
            { childName: "报价方案", url: false, id: "offerPlan" },
          ],
        },
        {
          name: "媒体中心",
          id: 4,
          url: "/newsHome",
        },
        {
          name: "关于我们",
          id: 5,
          url: "/ourselves",
          children: [
            { childName: "公司介绍", url: true, id: "ourselves" },
            { childName: "联系我们", url: true, id: "callMe" },
          ],
        },
      ];
    }
    this.navList.forEach((val) => {
      if (val.id == this.activeId) {
        val.active = "active";
      }
    });
  },
  created() {
    window.addEventListener("scroll", this.getScroll);
  },
};
</script>

<style scoped>
.header_main {
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 9;
}
.header_main:hover {
  background: #fff;
}
.header_main:hover .headNav .headNav_ul,
.header_main:hover .headNav_item > a {
  color: #000;
}

.headNav_item {
  position: relative;
}

.headNav_item > a {
  color: #fff;
  font-weight: 400;
}

.headNav_item-pop {
  position: absolute;
  top: 4rem;
  background: #fff;
  width: 100%;
  box-sizing: border-box;
}

.headNav_item-pop > p {
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #666666;
}

.headNav_item-pop > p:hover {
  color: #000;
  font-weight: bold;
  background: #f8f8f8;
}

.header_row_mobile {
  width: 100%;
}
.menu_icon {
  width: 1rem;
  height: 1rem;
}
.header_main_scroll {
  background: #fff;
}
.header_main_scroll .headNav .headNav_ul,
.header_main_scroll .headNav_item > a {
  color: #000;
}

.logo {
  display: flex;
  align-items: center;
  height: 4rem;
  cursor: pointer;
}

.header_main_mobile_scroll {
  background-color: #fff;
}

.header_main_mobile .logo {
  height: 3rem;
  padding-left: 1rem;
}
.header_main_mobile .menu {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
  padding-right: 1rem;
}
.header_main_mobile .logo img {
  width: 6rem;
}
.headNav .headNav_ul {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  color: #ffffff;
  align-items: center;
  height: 4rem;
}
.headNav_item {
  width: 9rem;
  height: 4rem;
  line-height: 4rem;
  text-align: center;
  cursor: pointer;
}
.active {
  position: relative;
}
.active::after {
  content: "";
  position: absolute;
  width: 3rem;
  height: 0.25rem;
  background-image: linear-gradient(
      90deg,
      #30c0fc 0%,
      #279ff9 70%,
      #1e7ef6 100%
    ),
    linear-gradient(#30c0fc, #30c0fc);
  bottom: 0;
  left: calc((100% - 3rem) / 2);
  border-radius: 1rem;
}
::-webkit-scrollbar {
  display: none;
}
.switch {
  font-size: 2rem;
  margin: 0.2rem 0 0 0.5rem;
  position: fixed;
  z-index: 9999;
  color: rgb(68, 127, 255);
  display: none;
}
.mobile_menu_main {
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
}
.mobile_menu_main_box {
  position: relative;
  background: #fff;
  width: 80%;
  height: 100%;
}
.menuClose {
  color: #000;
}
.mobile_menu_head {
  padding: 1rem 1rem 4rem 0;
  text-align: right;
}
.mobile_menu_list {
  margin-left: 4rem;
}
.mobile_menu_item {
  padding: 1.5rem 0;
  color: #2c2c2c;
}
.mobile_menu_callMe {
  color: #ccc;
  position: absolute;
  bottom: 5rem;
  left: 4rem;
}
@media screen and (max-width: 414px) {
  .switch {
    display: block;
  }

  .headNav_item {
    margin: 0 0.5rem;
    flex-shrink: 0;
  }
  .headNav .headNav_ul {
    overflow: scroll;
    height: 2.6rem;
    margin-left: 2.5rem;
  }
  .headNav {
    width: 100%;
  }
  .active::after {
    width: 4rem;
  }
}
</style>
