<template>
  <div class="backTop">
    <div class="backTop_list" :class="{ active: isShow }">
      <!-- <el-popover
        placement="left"
        title="手机号码"
        trigger="hover"
        content="13922304039 / 肖先生"
      >
        <p class="backTop-item tel" slot="reference">
          <img :src="imgUrl + 'icon-phone.png'" />
        </p>
      </el-popover>
      <el-popover popper-class="backTop-wx" placement="left" trigger="hover">
        <img style="width: 130px" :src="imgUrl + 'qrCode.jpg'" />
        <p>扫码关注公众号</p>
        <p class="backTop-item wxchat" slot="reference">
          <img :src="imgUrl + 'icon-wechat.png'" />
        </p>
      </el-popover>
      <p class="backTop-item board" @click="openBoard">
        <img :src="imgUrl + 'icon-board.png'" />
      </p> -->
      <p class="backTop-item top" @click="backTop">
        <img :src="imgUrl + 'icon-top.png'" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "backTop",
  data() {
    return {
      imgUrl: "",
      isShow: "",
      // 左边栏
      boardActive: "",
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    window.addEventListener("scroll", this.getScroll);
  },
  methods: {
    backTop() {
      document
        .getElementById("app")
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
    getScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 10) {
        this.isShow = 1;
      } else {
        this.isShow = 0;
      }
    },
    // 打开留言板
    openBoard() {
      this.$emit("switchBoard", true);
    },
  },
};
</script>

<style scoped>
.backTop {
  width: 10rem;
  height: 10rem;
  position: fixed;
  right: -98px;
  bottom: -60px;
  z-index: 99;
}

.backTop_list {
  width: 60px;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.backTop-item {
  width: 100%;
  height: 60px;
  display: flex;
  color: #fff;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
}

.top {
  opacity: 0;
  transition: opacity 0.5s;
  cursor: default;
  border-radius: 0 0 10px 10px;
}

.active .backTop-wx .wechat {
  border-radius: 0;
}

.active > .top {
  opacity: 0.99;
  cursor: pointer;
}

.backTop-item.board {
  border-radius: 0 0 10px 10px;
  overflow: hidden;
}

.active .backTop-item.board {
  border-radius: 0;
}
</style>
