<template>
  <div class="servicePlan">
    <v-header :activeId="3"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <section class="widthBox">
      <div class="service" id="service">
        <div class="service_title">
          <p class="title_big">服务流程</p>
          <p class="title_small">
            顾诚为各行各业提供定制化解决方案，运用专业高清设备拍摄，全自动化照片建模，给用户带来一流体验
          </p>
          <p class="title_small">精致到每一个步骤，让每一个客户满意</p>
        </div>
        <div class="service_flow">
          <div
            class="service_flow-item"
            v-for="(item, index) in serviceList"
            :key="index"
          >
            <div class="service_flow-item-left">
              <img :src="imgUrl + item.src" />
              <p>{{ item.name }}</p>
              <p>{{ item.desc }}</p>
            </div>
            <img v-if="item.arrow" :src="imgUrl + item.arrow" />
          </div>
        </div>
      </div>
      <div class="offerPlan" id="offerPlan">
        <div class="offerPlan_title">
          <p class="title_big">报价方案</p>
          <p class="title_small">
            我们提供多种服务套餐，以满足不同行业的企业需求
          </p>
        </div>
        <div class="offerPlan_plan">
          <div class="offerPlan_plan-item item1">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>A</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;基础版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ aPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>空间面积：</span>
              <div class="plan_area">
                <p
                  :class="{ active: aIndex == 1 }"
                  @click="planArea('a', 1, 9800)"
                >
                  300㎡
                </p>
                <p
                  :class="{ active: aIndex == 2 }"
                  @click="planArea('a', 2, 16800)"
                >
                  600㎡
                </p>
                <p
                  :class="{ active: aIndex == 3 }"
                  @click="planArea('a', 3, 25800)"
                >
                  1000㎡
                </p>
              </div>
            </div>
          </div>
          <div class="offerPlan_plan-item item2">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>B</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;标准版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ bPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>空间面积：</span>
              <div class="plan_area">
                <p
                  :class="{ active: bIndex == 1 }"
                  @click="planArea('b', 1, 15800)"
                >
                  300㎡
                </p>
                <p
                  :class="{ active: bIndex == 2 }"
                  @click="planArea('b', 2, 29800)"
                >
                  600㎡
                </p>
                <p
                  :class="{ active: bIndex == 3 }"
                  @click="planArea('b', 3, 46800)"
                >
                  1000㎡
                </p>
              </div>
            </div>
          </div>
          <div class="offerPlan_plan-item item3">
            <div class="offerPlan_plan-item-top">
              <p class="plan_font-name">
                <span>C</span>套餐&nbsp;&nbsp;&nbsp;&nbsp;升级版
              </p>
              <p class="plan_font-price"><span>￥</span>{{ cPrice }}</p>
            </div>
            <div class="offerPlan_plan-item-bottom">
              <span>空间面积：</span>
              <div class="plan_area">
                <p
                  :class="{ active: cIndex == 1 }"
                  @click="planArea('c', 1, 18800)"
                >
                  300㎡
                </p>
                <p
                  :class="{ active: cIndex == 2 }"
                  @click="planArea('c', 2, 35800)"
                >
                  600㎡
                </p>
                <p
                  :class="{ active: cIndex == 3 }"
                  @click="planArea('c', 3, 56800)"
                >
                  1000㎡
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="offerPlan_content">
          <div class="offerPlan_content-title">
            <div class="title-left">
              <p class="font-fill"></p>
              <p class="title-skill">功能</p>
              <p class="title-desc">描述</p>
            </div>
            <div class="title-plan">
              <p class="title-plan-item"><span>A</span>版</p>
              <p class="title-plan-item"><span>B</span>版</p>
              <p class="title-plan-item"><span>C</span>版</p>
            </div>
          </div>
          <div class="offerPlan_content-basic">
            <p class="font-fill">标准功能</p>
            <div class="basic-right">
              <div
                class="offerPlan_content-basic-item"
                v-for="(item, index) in basicList"
                :key="index"
              >
                <p class="basic-skill">
                  <span>{{ index + 1 }}</span
                  >{{ item.name }}
                </p>
                <p class="basic-desc">{{ item.desc }}</p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.aFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.bFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.cFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
              </div>
            </div>
          </div>
          <div class="offerPlan_content-expert">
            <p class="font-fill">高级功能</p>
            <div class="basic-right">
              <div
                class="offerPlan_content-basic-item"
                v-for="(item, index) in expertList"
                :key="index"
              >
                <p class="basic-skill">
                  <span>{{ basicList.length + index + 1 }}</span
                  >{{ item.name }}
                </p>
                <p class="basic-desc">
                  {{ item.desc }}
                  <span class="basic-label" v-show="item.label">{{
                    item.label
                  }}</span>
                </p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.aFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.bFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
                <p class="offerPlan-icon">
                  <img
                    :src="item.cFlag ? imgUrl + 'yes.png' : imgUrl + 'no.png'"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="offerPlan_footer">
          <p>
            植入元素及相关协同工作需客户配合提供，具体方案报价、下单拍摄、功能定制等问题，请联系咨询。
          </p>
          <p>另：标签热点功能A套餐含10个，B、C套餐含20个</p>
        </div>
      </div>
    </section>
    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import boxOne from "../../components/boxOne";
import footer from "../../components/footer";
import backTop from "../../components/backTop";
import board from "../../components/board";

export default {
  name: "servicePlan",
  components: {
    "v-header": header,
    "v-footer": footer,
    boxOne: boxOne,
    backTop: backTop,
    board: board,
  },
  data() {
    return {
      boardShow: false,
      title: "服务方案",
      content: "一站式量身定制服务方案，有效助力企业营销推广",
      bg: "banner-service.jpg",
      imgUrl: "",
      serviceList: [
        {
          src: "service-step-1.png",
          name: "需求沟通",
          desc: "明确需求 签订合同",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-2.png",
          name: "扫描拍摄",
          desc: "最快30分钟 极速拍摄",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-3.png",
          name: "模型制作",
          desc: "三维建模 云端处理",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-4.png",
          name: "个性化功能",
          desc: "多功能定制处理",
          arrow: "service-step-arrow.png",
        },
        {
          src: "service-step-6.png",
          name: "验收交付",
          desc: "链接交付展示",
          arrow: "",
        },
      ],
      // 套餐
      aPrice: 9800,
      bPrice: 15800,
      cPrice: 18800,
      aIndex: 1,
      bIndex: 1,
      cIndex: 1,
      //基础功能
      basicList: [
        {
          name: "三维场景模型",
          desc: "空间扫描，三维模型构建",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "3D漫游",
          desc: "等比还原场景，步进式沉浸式漫游",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "上帝视角",
          desc: "上帝视角下，多视角看三维模型，看空间布局",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "平面图",
          desc: "查看空间平面图",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "场景切换",
          desc: "通过场景快捷导览，快速切换到想去场景",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "标签热点",
          desc: "植入文字、语音、图片、模型、视频等资料",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "播放功能",
          desc: "点击自动导览，用户可以在空间内沿场景自动漫游",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "流量球",
          desc: "提供访客浏览量数据，可自定义如展厅面积等数值",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
      ],
      // 高级功能
      expertList: [
        {
          name: "分享",
          desc: "支持全网一键分享",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "背景音乐",
          desc: "在VR空间添加背景音乐",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "滤镜",
          desc: "在VR空间添加颜色滤镜",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "LOGO",
          desc: "在VR空间添加品牌LOGO",
          aFlag: true,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "嵌入公司官网",
          desc: "嵌入公司官网",
          aFlag: false,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "H5页面形式",
          desc: "提供H5页面模板",
          aFlag: false,
          bFlag: true,
          cFlag: true,
          label: "需提供模板所需资料",
        },
        {
          name: "密码功能",
          desc: "提供访问权限加密功能",
          aFlag: false,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "小地图",
          desc: "在VR空间添加小地图",
          aFlag: false,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "指南针",
          desc: "在VR空间添加指南针",
          aFlag: false,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "一键联系",
          desc: "一键拨打电话，添加联系二维码",
          aFlag: false,
          bFlag: true,
          cFlag: true,
          label: "需提供联系信息",
        },
        {
          name: "一键预约",
          desc: "一键预约",
          aFlag: false,
          bFlag: true,
          cFlag: true,
        },
        {
          name: "官网跳转",
          desc: "在VR空间添加官网跳转链接",
          aFlag: false,
          bFlag: true,
          cFlag: true,
          label: "需提供链接",
        },
        {
          name: "数据分析",
          desc: "访问量、访问时间、停留时长、访客信息分析等",
          aFlag: false,
          bFlag: false,
          cFlag: true,
        },
        {
          name: "验证访问",
          desc: "登记联系信息，验证访问",
          aFlag: false,
          bFlag: false,
          cFlag: true,
        },
      ],
    };
  },
  mounted() {
    this.imgUrl = this.$imgUrl;

    if (this.$route.query.id) {
      this.$nextTick(() => {
        document
          .getElementById(this.$route.query.id)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      });
    }
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
    planArea(type, index, price) {
      switch (type) {
        case "a":
          this.aIndex = index;
          this.aPrice = price;
          break;
        case "b":
          this.bIndex = index;
          this.bPrice = price;
          break;
        case "c":
          this.cIndex = index;
          this.cPrice = price;
          break;
      }
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.service_title > .title_big,
.offerPlan_title > .title_big {
  font-weight: bold;
  font-size: 2rem;
  text-align: center;
  padding-top: 7rem;
  padding-bottom: 1rem;
}

.service_title > .title_small,
.offerPlan_title > .title_small {
  margin: 1rem 0;
  text-align: center;
}

.service_flow {
  margin-top: 2rem;
  width: 100%;
  height: 300px;
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/service-step.png")
    no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.service_flow-item {
  display: flex;
  color: #ffffff;
  align-items: center;
  width: 20%;
  justify-content: center;
  position: relative;
}

.service_flow-item > img {
  width: 35px;
  height: 20px;
  position: absolute;
  right: -17.5px;
}

.service_flow-item-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.service_flow-item-left > img {
  margin-bottom: 0.8rem;
}

.service_flow-item-left > p {
  color: #cccccc;
  font-size: 12px;
}

.service_flow-item-left > p:first-of-type {
  margin-bottom: 0.8rem;
  font-weight: bold;
  color: #ffffff;
  font-size: 16px;
}

.offerPlan {
  padding-bottom: 8rem;
}

.offerPlan_plan {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0;
}

.offerPlan_plan-item {
  width: 340px;
  height: 140px;
  background-position: center;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px;
  box-sizing: border-box;
}

.item1 {
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/A.png")
    no-repeat;
}

.item2 {
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/B.png")
    no-repeat;
}
.item3 {
  background: url("https://goodvr.oss-cn-shenzhen.aliyuncs.com/gcweb/C.png")
    no-repeat;
}

.offerPlan_plan-item-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.offerPlan_plan-item-bottom {
  width: 100%;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan_font-name {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.plan_font-name > span {
  font-size: 36px;
  font-style: italic;
  margin-right: 5px;
}

.plan_font-price {
  font-size: 22px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
}

.plan_font-price > span {
  font-size: 16px;
}

.offerPlan_plan-item-bottom > span {
  font-size: 16px;
  color: #ffffff;
}

.plan_area {
  width: 211px;
  border: 1px solid rgb(255, 255, 255, 0.5);
  border-radius: 14px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.plan_area > p {
  width: 33.3333%;
  height: 28px;
  line-height: 28px;
  text-align: center;
  border-radius: 14px;
  cursor: pointer;
}

.plan_area > .active {
  background-color: rgb(255, 255, 255, 0.5);
}

.offerPlan_content-title {
  height: 80px;
  background: #2c3357;
  color: #ffffff;
}

.title-left,
.title-plan,
.offerPlan_content-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.font-fill {
  width: 80px;
  writing-mode: tb-rl;
  letter-spacing: 10px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.basic-right {
  background-color: #fff;
}

.title-skill {
  width: 180px;
  height: 100%;
  text-align: center;
  font-weight: bold;
  line-height: 80px;
}

.title-desc {
  width: 430px;
  height: 100%;
  font-weight: bold;
  line-height: 80px;
}

.title-plan > p {
  width: 130px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  font-style: italic;
  border-left: 1px solid rgb(255, 255, 255, 0.5);
  box-sizing: border-box;
}

.title-plan > p > span {
  font-size: 30px;
  margin-right: 5px;
  font-weight: bold;
}

.offerPlan_content-basic,
.offerPlan_content-expert {
  display: flex;
  align-items: center;
  background: #f8f8f8;
}

.offerPlan_content-basic {
  border-bottom: 1px solid #e8e8e8;
}

.offerPlan_content-basic-item {
  display: flex;
  align-items: center;
}

.offerPlan_content-basic-item > p {
  height: 60px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  font-family: Source Han Sans CN;
}

.basic-skill {
  width: 180px;
  font-weight: bold;
  color: #000000;
  border-bottom: 1px solid #e8e8e8;
}

.basic-skill > span {
  font-weight: bold;
  width: 30px;
  text-align: center;
  margin-right: 5px;
}

.basic-desc {
  position: relative;
  width: 430px;
  font-weight: 400;
  font-size: 14px;
  color: #666666;
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
}

.basic-label {
  position: absolute;
  right: 10px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border: 1px solid #fe8a21;
  color: #fe8a21;
  padding: 0 5px;
}

.offerPlan-icon {
  width: 130px;
  justify-content: center;
  box-sizing: border-box;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.offerPlan_footer {
  height: 80px;
  background: #f8f8f8;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.offerPlan_footer > p {
  font-size: 12px;
  color: #666666;
}

.offerPlan_footer > p:first-of-type {
  font-size: 14px;
  font-weight: bold;
  color: #222222;
}
</style>
