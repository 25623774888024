<template>
  <div class="caseShow">
    <v-header :activeId="2"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <section class="caseShow_content widthBox">
      <div
        class="caseShow_content-item"
        :class="{ active: !item.flag }"
        :id="item.id"
        v-for="(item, index) in caseShowList"
        :key="index"
      >
        <div class="caseShow_content-left">
          <p class="content-left-title">
            <span>{{ item.titleNum }}</span
            >{{ item.title }}
            <span></span>
          </p>
          <div class="content-left-hint">
            <p class="content-left-hint-bold"></p>
          </div>
          <p class="content-left-text">{{ item.text }}</p>
          <p class="content-left-desc">
            {{ item.desc }}
          </p>
          <ul class="content-left-ul">
            <li v-for="(items, idx) in item.ulList" :key="idx">
              <div class="content-left-ul-label"></div>
              <p>{{ items }}</p>
            </li>
          </ul>
        </div>
        <div
          class="caseShow_content-right"
          :style="{ backgroundImage: 'url(' + imgUrl + item.src }"
        >
          <div class="item-hint"></div>
          <p class="content-right-btn" @click="toModel(item.modelUrl)">
            查看案例 <img :src="imgUrl + 'arrow-white.png'" alt="VR实景" />
          </p>
        </div>
      </div>
      <div class="caseShow_content-item more" id="moreIndustry">
        <div class="caseShow_content-top">
          <p class="content-left-title">
            <span>05</span>更多行业
            <span
              >VR实景技术还广泛应用于家居&nbsp;&nbsp;学校&nbsp;&nbsp;各类商铺&nbsp;&nbsp;物品展示等更多行业</span
            >
          </p>
          <div class="content-left-hint">
            <p class="content-left-hint-bold"></p>
          </div>
        </div>
        <div class="caseShow_content-bottom">
          <div
            class="caseShow_content-more-item"
            v-for="(item, index) in moreList"
            :key="index"
            :style="{ backgroundImage: 'url(' + imgUrl + item.src }"
          >
            <div class="item-hint"></div>
            <p class="content-right-btn" @click="toModel(item.modelUrl)">
              查看案例 <img :src="imgUrl + 'arrow-white.png'" alt="VR实景" />
            </p>
          </div>
        </div>
      </div>
    </section>
    <v-footer></v-footer>
    <backTop class="home_backTop" @switchBoard="switchBoard"></backTop>
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import footer from "../../components/footer";
import boxOne from "../../components/boxOne";
import backTop from "../../components/backTop";
import board from "../../components/board";

export default {
  name: "caseShow",
  components: {
    "v-header": header,
    "v-footer": footer,
    boxOne: boxOne,
    backTop: backTop,
    board: board,
  },
  data() {
    return {
      imgUrl: "",
      title: "行业解决方案",
      content: "行业应用广泛，覆盖多场景，让展示更生动",
      bg: "banner-case1.jpg",
      boardShow: false,
      caseShowList: [
        {
          id: "vrExhibition",
          titleNum: "01",
          title: "企业展厅",
          text: "协助企业内容升级，产品线上线下升级",
          desc:
            "痛点：照片、视频等二维信息难以全面传递企业信息，近些年，随着互联网和数字信息化的迅速发展，让不少企业早早地感受到了危机",
          ulList: [
            "为外贸企业量身打造，海外客户足不出户就能通透验厂，促进成交机会",
            "随时随地看展厅、车间、仓库等，便捷获取产品信息及价格",
            "24小时永不落幕，颠覆图文形式，让客户过目不忘",
          ],
          src: "01qiye.jpg",
          flag: true,
          modelUrl:
            "https://my.513dvr.com/view2/vr/goodZUgPCiC2UV5.html?m=ZUgPCiC2UV5",
        },
        {
          id: "vrMuseum",
          titleNum: "02",
          title: "数字文博",
          text: "VR云游博物馆，感受触手可及的文化",
          desc:
            "痛点：我国历史悠久、幅员辽阔，文化遗产资源庞大，受时间和地域的限制，历史文化难以传播，且文物不易保存、移动和外展的特点让历史传承面临巨大问题",
          ulList: [
            "结合图文视频、语音讲解等标签热点，丰富信息传递，文化传播效率更高",
            "文物数字化存档，达到了保护文物、传承历史的目的",
            "跨越时间和地域的限制，让传统文化在数字时代更加璀璨",
          ],
          src: "02wenbo.jpg",
          flag: false,
          modelUrl: "https://my.513dvr.com/view2/vr/fangzhiguan.html#1L",
        },
        {
          id: "vrHotel",
          titleNum: "03",
          title: "民宿酒店",
          text: "VR民宿看房，随时随地体验“虚拟入住”",
          desc:
            "痛点：传统民宿酒店的展示都是通过图文、视频等传递信息，用户入住前难以准确掌握真实情况，容易做出错误判断，从而导致满意度下降",
          ulList: [
            "增强用户体验感，让用户在预选环节时间延长",
            "场景真实克隆，所见即所得，解决信息不对称问题，提高用户满意度",
            "三维立体展示，民宿吸粉获客更容易",
          ],
          src: "03minsu.jpg",
          flag: true,
          modelUrl: "https://my.513dvr.com/LWaK3QfWyuY.html?m=LWaK3QfWyuY",
        },
        {
          id: "vrHouse",
          titleNum: "04",
          title: "VR看房",
          text: "高效看房，身临其境，更懂地产的VR",
          desc:
            "痛点：不方便实地看房？担心房源真实性？装修设计测量困难？想看样板间太麻烦？",
          ulList: [
            "打造最真实的线上看房体验，全新沉浸式体验开创消费新模式",
            "远程线上带看，无需排队，高效看房",
            "数据化标尺复刻真实空间，房源信息、户型、面积、方位朝向全面展示",
          ],
          src: "04kanfang.jpg",
          flag: false,
          modelUrl: "https://my.513dvr.com/view/n4rswHwttk5.html",
        },
      ],
      moreList: [
        {
          src: "05-1jiaju.jpg",
          modelUrl: "https://my.513dvr.com/view/SEfCG7npPjm.html",
        },
        {
          src: "05-2xuexiao.jpg",
          modelUrl: "https://720yun.com/t/dfvkOlih0iw?scene_id=51426579",
        },
        {
          src: "05-3canyin.jpg",
          modelUrl: "https://my.513dvr.com/pJtb3ZvYP4w.html?m=pJtb3ZvYP4w",
        },
        {
          src: "05-4jianshenf.jpg",
          modelUrl: "https://my.513dvr.com/peUB6Pooapf.html?m=peUB6Pooapf",
        },
      ],
    };
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    if (this.$route.query.id) {
      this.$nextTick(() => {
        document
          .getElementById(this.$route.query.id)
          .scrollIntoView({ block: "start", behavior: "smooth" });
      });
    }
  },
  methods: {
    // 前往模型
    toModel(val) {
      window.open(val, "_blank");
    },
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
  },
};
</script>

<style scoped>
.caseShow_content {
  padding: 40px 0;
  box-sizing: border-box;
}

.caseShow_content-item {
  width: 100%;
  height: 480px;
  background: #f8f8f8;
  display: flex;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
}

.more {
  flex-direction: column;
  padding: 60px 40px 40px 40px;
  box-sizing: border-box;
}

.caseShow_content-item.active {
  background: #ffffff;
  flex-direction: row-reverse;
}

.caseShow_content-left,
.caseShow_content-right {
  width: 500px;
  height: 340px;
  font-family: Source Han Sans SC;
}

.caseShow_content-right,
.caseShow_content-item.active > .caseShow_content-left {
  margin: 0 40px 0 20px;
}

.caseShow_content-left,
.caseShow_content-item.active > .caseShow_content-right {
  margin: 0 20px 0 40px;
}

.caseShow_content-top {
  width: 100%;
  height: 100px;
}

.caseShow_content-bottom {
  width: 100%;
  height: calc(100% - 180px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-left-title {
  position: relative;
  font-size: 28px;
  font-weight: bold;
  color: #222222;
}

.content-left-title > span:first-of-type {
  margin-right: 10px;
  font-style: italic;
}

.content-left-title > span:last-of-type {
  position: absolute;
  right: 0;
  font-size: 20px;
  top: 5px;
}

.content-left-hint {
  margin: 40px 0;
  width: 100%;
  height: 1px;
  background: #eeeeee;
}

.content-left-hint-bold {
  width: 30px;
  height: 2px;
  background: #000000;
}

.content-left-text {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
  margin: 20px 0;
}

.content-left-desc {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 26px;
  margin-bottom: 30px;
}

.content-left-ul > li {
  font-size: 14px;
  font-weight: 400;
  color: #222222;
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.content-left-ul-label {
  width: 12px;
  height: 12px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 50%;
  margin-right: 10px;
}

.caseShow_content-right {
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.caseShow_content-more-item {
  width: 265px;
  height: 265px;
  margin: 0 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  position: relative;
}

.item-hint {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.5s;
}

.caseShow_content-right:hover > .item-hint,
.caseShow_content-more-item:hover > .item-hint {
  opacity: 1;
}

.caseShow_content-right:hover > .content-right-btn,
.caseShow_content-more-item:hover > .content-right-btn {
  -webkit-animation: fade-in-bck 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in-bck 0.5s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.content-right-btn {
  position: absolute;
  left: calc((100% - 128px) / 2);
  bottom: calc((100% - 40px) / 2);
  width: 128px;
  height: 40px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 20px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  opacity: 0;
  z-index: 3;
  -webkit-animation: fade-out-fwd 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: fade-out-fwd 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  cursor: pointer;
}

.content-right-btn > img {
  margin-left: 10px;
  width: 16px;
}
</style>
