import axios from "axios";
import { Message } from "element-ui";

// create an axios instance
const service = axios.create({
  baseURL: "https://api.513dvr.com", // url = base url + request url
  // baseURL: "http://127.0.0.1:7001",
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    //在发送请求之前做某件事
    return config;
  },
  (error) => {
    // Indicator.close()
    Message.error(`错误的传参`);
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (res) => {
    //对响应数据做些事
    return res;
  },
  (error) => {
    if (error.message.includes("timeout")) {
      return Promise.reject("网络超时");
    }
    return Promise.reject(error);
  }
);

export default service;
