<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import vueTouch from "@/components/vueTouch.js";
export default {
  name: "App",
};
</script>

<style>
@import "./css/animation.css";
* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  font-family: SourceHanSansCN-Bold;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* custom */
a {
  color: #000;
  font-weight: bold;
  text-decoration: none;
  -webkit-backface-visibility: hidden;
}

li {
  list-style: none;
}

::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar-track-piece {
  background-color: rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:vertical {
  height: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

::-webkit-scrollbar-thumb:horizontal {
  width: 5px;
  background-color: rgba(125, 125, 125, 0.7);
  -webkit-border-radius: 6px;
}

html,
body {
  width: 100%;
}

body {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.widthBox {
  width: 1080px;
  margin: 0 auto;
}
.widthBox_mobile {
  width: 90vw;
  margin: 0 auto;
}
.title {
  font-weight: bold;
}
@media only screen and (max-width: 414px) {
  .widthBox {
    width: 100%;
  }
}

body .backTop-wx {
  text-align: center;
}

button {
  border: 0 none;
  outline: none;
}

/*取消button点击的默认样式*/
button:focus {
  border: 0 none;
  outline: none;
}
</style>
