<template>
  <div class="ourselves">
    <v-header></v-header>
    <section
      class="callMe_banner"
      :style="{
        backgroundImage: 'url(' + imgUrl + 'callMe/banner-aboutus.jpg)',
      }"
    >
      <div class="callMe_banner-title flex">
        <p class="title_big">关于我们</p>
        <p class="title_small">顾诚智能科技·三维可视化服务提供商</p>
      </div>
    </section>
    <ul class="callMe-navBar flex">
      <li
        :class="{ active: navIndex == index }"
        v-for="(item, index) in navBar"
        :key="index"
        @click="scrollTo(index, item.url)"
      >
        {{ item.title }}
      </li>
    </ul>
    <section class="widthBox_mobile" id="company">
      <div class="callMe_company">
        <div class="callMe_content-title">
          <p class="title_big">公司介绍</p>
          <p class="title_small">广东顾诚智能科技有限公司</p>
        </div>
      </div>
      <img
        class="callMe_company-img"
        :src="imgUrl + 'callMe/aboutus-img.jpg'"
        alt="顾诚智能"
      />
      <p class="callMe_company-title">
        广东顾诚智能科技有限公司是一家三维可视化服务专业提供商。
      </p>
      <p class="callMe_company-desc">
        我们致力于通过VR和3D重建等前沿创新技术，为公众还原一个真实可交互的实景空间，让信息的传递更便捷有效，沉浸式体验让用户所见即所得，打破时间和空间的限制，全面提升用户的视觉及交互体验。
      </p>
      <p class="callMe_company-desc">
        我们力求精致到每一个步骤，让每一个客户满意；我们为各行各业提供定制化解决方案，运用专业激光扫描设备拍摄，全自动化数字建模，给用户带来一流体验。
      </p>
    </section>
    <section class="widthBox_mobile">
      <div class="callMe_partner">
        <div class="callMe_content-title">
          <p class="title_big">合作伙伴</p>
          <p class="title_small">以客户为中心 全力以赴</p>
        </div>
      </div>
      <div class="callMe_partner-item flex">
        <img
          v-for="(item, index) in cooperationList"
          :key="index"
          :src="imgUrl + item.image"
        />
      </div>
    </section>
    <section class="widthBox_mobile" id="callMe">
      <div class="callMe_info" id="">
        <div class="callMe_content-title">
          <p class="title_big">联系我们</p>
          <p class="title_small">我们随时准备好 为您提供最专业的服务和支持</p>
        </div>
      </div>
    </section>
    <section class="callMe_tel widthBox_mobile">
      <div class="callMe_tel-title flex">
        <p class="callMe_tel-title-bg">Contact us</p>
        <p class="callMe_tel-title-content">联系咨询</p>
        <div class="callMe_tel-title-hint"></div>
      </div>
      <div class="callMe_tel-body">
        <p><i class="el-icon-phone"></i>139 2230 4039</p>
        <p><i class="el-icon-mobile-phone"></i>139 2230 4039 / 肖先生</p>
      </div>
    </section>
    <section class="callMe_address widthBox_mobile">
      <div class="callMe_address-title flex">
        <p class="callMe_address-title-bg">Address</p>
        <p class="callMe_address-title-content">办公地址</p>
        <div class="callMe_address-title-hint"></div>
      </div>
      <p class="callMe_address-item">
        广东省 东莞市 南城区 莞太路 篁村段22号 福民大厦505
      </p>
      <formSubmit-mobile></formSubmit-mobile>
    </section>
    <v-footer-mobile></v-footer-mobile>
    <board_mobile></board_mobile>
  </div>
</template>

<script>
import header from "../../components/header";
import footer_mobile from "../../components/footer_mobile";
import formSubmit_mobile from "../../components/formSubmit_mobile";
import board_mobile from "../../components/board_mobile";

export default {
  name: "ourselves",
  data() {
    return {
      imgUrl: "",
      navBar: [
        { title: "公司介绍", url: "company" },
        { title: "联系我们", url: "callMe" },
      ],
      navIndex: 0,
      cooperationList: [
        {
          image: "partner-aly.png",
          id: 0,
        },
        {
          image: "partner-bxjp.png",
          id: 1,
        },
        {
          image: "partner-fzg.png",
          id: 4,
        },
        {
          image: "partner-gsj.png",
          id: 5,
        },
        {
          image: "partner-hw.png",
          id: 6,
        },
        {
          image: "partner-hy.png",
          id: 7,
        },
        {
          image: "partner-lfy.png",
          id: 8,
        },
        {
          image: "partner-mf.png",
          id: 9,
        },
        {
          image: "partner-ah.png",
          id: 10,
        },
        {
          image: "partner-dj.png",
          id: 12,
        },
        {
          image: "partner-dx.png",
          id: 13,
        },
        {
          image: "partner-fitinn.png",
          id: 14,
        },
        {
          image: "partner-gots.png",
          id: 15,
        },
        {
          image: "partner-mxhl.png",
          id: 16,
        },
        {
          image: "partner-ssjj.png",
          id: 17,
        },
        {
          image: "partner-swj.png",
          id: 18,
        },
        {
          image: "partner-tj.png",
          id: 19,
        },
        {
          image: "partner-tlh.png",
          id: 20,
        },
        {
          image: "partner-cxkj.png",
          id: 21,
        },
        {
          image: "partner-tx.png",
          id: 22,
        },
        {
          image: "partner-ut.png",
          id: 23,
        },
        {
          image: "partner-wywhq.png",
          id: 24,
        },
        {
          image: "partner-xc.png",
          id: 25,
        },
        {
          image: "partner-ch.png",
          id: 26,
        },
        {
          image: "partner-ty.png",
          id: 27,
        },
        {
          image: "partner-whglm.png",
          id: 28,
        },
        {
          image: "partner-whhjj.png",
          id: 29,
        },
        {
          image: "partner-whlsdl.png",
          id: 30,
        },
        {
          image: "partner-whsn.png",
          id: 31,
        },
        {
          image: "partner-yc.png",
          id: 32,
        },
      ],
    };
  },
  components: {
    "v-header": header,
    "v-footer-mobile": footer_mobile,
    "formSubmit-mobile": formSubmit_mobile,
    board_mobile: board_mobile,
  },
  mounted() {
    this.imgUrl = this.$imgUrl;

    if (this.$route.query.id) {
      this.$nextTick(() => {
        setTimeout(() => {
          document
            .getElementById(this.$route.query.id)
            .scrollIntoView({ block: "start", behavior: "smooth" });
          this.$route.query.flag && (this.centreIndex = this.$route.query.flag);
        }, 200);
      });
    }
  },
  methods: {
    // 锚点
    scrollTo(index, val) {
      this.navIndex = index;
      document
        .getElementById(val)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  beforeRouteUpdate(to) {
    if (to.query.id) {
      document
        .getElementById(to.query.id)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  },
};
</script>

<style scoped>
.callMe {
  overflow-x: hidden;
}

.flex {
  display: flex;
}

.callMe_banner {
  width: 100vw;
  height: 200px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.callMe_banner-title {
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.callMe_banner-title > .title_big {
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.callMe_banner-title > .title_small {
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.8;
  text-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
}

.callMe-navBar {
  overflow-x: scroll;
  height: 44px;
  background: #f8f8f8;
  justify-content: center;
}

.callMe-navBar > .active {
  font-weight: bold;
}

.callMe-navBar > li {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
}

.callMe_content-title {
  margin-bottom: 30px;
}

.callMe_content-title > .title_big {
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 0.5rem;
}

.callMe_content-title > .title_small {
  margin-top: 1rem;
  text-align: center;
  font-size: 14px;
  position: relative;
  color: #999999;
}

.callMe_company-img {
  width: 100%;
}

.callMe_company-title {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
  line-height: 28px;
  margin: 30px 0;
}

.callMe_company-desc {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 28px;
  margin-bottom: 20px;
}

.callMe_partner-item {
  flex-wrap: wrap;
}

.callMe_partner-item > img {
  width: 33%;
}

.callMe_tel {
  height: 173px;
  background: #f8f8f8;
}

.callMe_address {
  margin-top: 40px;
}

.callMe_tel-title,
.callMe_address-title {
  position: relative;
  justify-content: center;
  align-items: flex-end;
  height: 65px;
  border-bottom: 1px solid #eeeeee;
  font-family: Source Han Sans CN;
}

.callMe_tel-title-bg,
.callMe_address-title-bg {
  height: 24px;
  font-size: 30px;
  font-weight: bold;
  font-style: italic;
  color: #eeeeee;
  position: absolute;
  top: 10px;
  margin: 0 auto;
}

.callMe_tel-title-content,
.callMe_address-title-content {
  z-index: 3;
  margin-bottom: 20px;
}

.callMe_tel-title-hint,
.callMe_address-title-hint {
  position: absolute;
  bottom: 0;
  width: 30px;
  height: 1px;
  background: #000000;
  left: calc((100% - 30px) / 2);
}

.callMe_tel-body {
  width: 100%;
  height: 107px;
  padding: 30px 40px;
  box-sizing: border-box;
}

.callMe_tel-body > p {
  font-size: 20px;
  font-family: SimHei;
  font-weight: bold;
  color: #333333;
  margin-bottom: 10px;
}

.callMe_tel-body > p > i {
  margin-right: 10px;
}

.callMe_address-item {
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #666666;
  line-height: 24px;
  margin-top: 30px;
  margin-bottom: 15px;
}
</style>
