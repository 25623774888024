// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import router from "./router";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "swiper/css/swiper.css";
import axios from "./request";
import VueAwesomeSwiper from "vue-awesome-swiper";
Vue.use(VueAwesomeSwiper);

const imgUrl = "//www.513dvr.com/gcweb/";
const flag = navigator.userAgent.match(
	/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
);

Vue.prototype.$axios = axios;
Vue.prototype.$imgUrl = imgUrl;
Vue.prototype.$flag = flag;
Vue.use(ElementUI);
Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App),
	mounted() {
		document.dispatchEvent(new Event("render-event"));
	},
}).$mount("#app");
