<template>
  <div id="formSubmit" class="banner formUp">
    <div class="widthBox">
      <div class="formUp_title title" v-if="titleIsShow">
        VR视觉新时代已来，您还止步不前吗？
      </div>
      <ul class="formUp_text" v-if="titleIsShow">
        <li class="formUp_text_item">
          我们已经准备好了，随时为您提供最专业的服务和支持
        </li>
        <li class="formUp_text_item">还等什么，现在就联系吧</li>
      </ul>
      <el-row class="formUp_main">
        <el-col :span="12">
          <ul class="formUp_info">
            <li class="formUp_infoItem">
              <span class="formUp_infoItem_title">咨询电话：</span>
              <span class="formUp_infoItem_content">0769-88993721</span>
            </li>
            <li class="formUp_infoItem">
              <span class="formUp_infoItem_title">联系方式：</span>
              <span class="formUp_infoItem_content">13922304039 / 肖先生</span>
            </li>
            <li class="formUp_infoItem">
              <span class="formUp_infoItem_title">办公地址：</span>
              <span class="formUp_infoItem_content">
                广东省东莞市南城区莞太路篁村段22号福民大厦505
              </span>
            </li>
            <li class="formUp_infoItem followUs">
              <div class="formUp_infoItem_left">
                <span class="formUp_infoItem_title">关注我们：</span>
                <span class="formUp_infoItem_content">
                  <span>关注"顾诚智能科技"微信公众号，进一步了解我们</span>
                </span>
              </div>
              <div class="formUp_infoItem_right">
                <img :src="imgUrl + 'qrCode.jpg'" class="formUp_qrCode" />
              </div>
            </li>
          </ul>
        </el-col>
        <el-col :span="12">
          <div class="address">
            <baidu-map
              ak="yzA4IfNHf8SMRPomH0tOiFOpiFwuMEAa"
              :center="center"
              :zoom="zoom"
              :Point="Point"
              class="bm-view"
            >
              <BmMarker :position="Point"></BmMarker>
            </baidu-map>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import BaiduMap from "vue-baidu-map/components/map/Map.vue";
import BmMarker from "vue-baidu-map/components/overlays/Marker";
export default {
  name: "formSubmit",
  data() {
    return {
      imgUrl: "",
      center: { lng: 113.750387, lat: 23.03119 },
      Point: {
        lng: 113.750387,
        lat: 23.03119,
      },
      zoom: 20,
    };
  },
  props: ["titleIsShow"],
  mounted() {
    this.imgUrl = this.$imgUrl;
  },

  components: {
    BaiduMap,
    BmMarker,
  },
};
</script>

<style scoped>
.formUp {
  width: 100%;
  display: flex;
  color: #000;
  margin-bottom: 6rem;
}
.formUp .formUp_title {
  text-align: center;
  padding-top: 7rem;
  font-size: 2.4rem;
}
.formUp .formUp_text {
  margin-top: 3rem;
  font-size: 1rem;
  color: #333333;
  text-align: center;
  line-height: 2rem;
}
.formUp .formUp_main {
  margin-top: 4rem;
}
.formUp .formUp_input {
  padding: 1rem 1rem 1.2rem 1rem;
  border-bottom: 1px solid #000000;
  display: flex;
  align-items: center;
  width: 25rem;
  font-size: 0.8rem;
  margin-top: 2rem;
}
.formUp .formUp_input img {
  width: 1rem;
  height: 1rem;
}
.formUp .formUp_input input {
  padding-left: 1rem;
  letter-spacing: 0.2rem;
  color: #333;
  outline: none;
  width: 80%;
}
.necessary {
  position: relative;
}
.necessary:after {
  content: "*";
  color: red;
  right: 2rem;
  position: absolute;
}
.formUp .formUp_btn {
  width: 7rem;
  height: 2.6rem;
  border-radius: 3rem;
  background: #000;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  margin-top: 4rem;
  cursor: pointer;
}
.formUp .formUp_prompt {
  color: #999999;
  font-size: 0.8rem;
  margin-top: 1rem;
}
.formUp .formUp_infoItem {
  color: #333;
  padding-bottom: 2rem;
  display: flex;
  line-height: 2rem;
}
.formUp .formUp_infoItem .formUp_infoItem_title {
  color: #000;
  font-weight: bold;
  width: 5rem;
  display: block;
  text-align: left;
  flex-shrink: 0;
}
.formUp .formUp_infoItem .formUp_infoItem_content {
  width: 22rem;
  padding-left: 1rem;
}
.formUp .formUp_infoItem .formUp_infoItem_content span {
  display: block;
  margin-bottom: 1rem;
}
.formUp .formUp_qrCode {
  width: 6rem;
  height: 6rem;
}
.formUp .formUp_wx {
  display: flex;
  align-items: center;
  font-size: 0.95rem;
  justify-content: space-between;
  width: 6rem;
  margin-top: 0.2rem;
  color: #333;
}
.formUp .formUp_wx img {
}
.bm-view {
  width: 34rem;
  height: 15rem;
}
.followUs {
  display: flex;
  /* justify-content: space-between; */
}
.formUp_infoItem_left {
  display: flex;
}
.followUs .formUp_infoItem_left .formUp_infoItem_content {
  width: 12rem;
}
.formUp_infoItem_right img {
  margin-left: 2rem;
}
@media only screen and (max-width: 414px) {
  .formUp .formUp_infoItem .formUp_infoItem_title {
    width: 4rem;
    font-size: 0.6rem;
  }
  .bm-view {
    width: 11rem;
    height: 9rem;
    margin-left: 1rem;
  }
  .formUp .formUp_infoItem .formUp_infoItem_content {
    font-size: 0.6rem;
  }
  .formUp .formUp_infoItem {
    line-height: 1.2rem;
    padding-bottom: 1rem;
  }
  .formUp .formUp_main {
    padding: 0 10px;
  }
  .followUs .formUp_infoItem_left .formUp_infoItem_content {
    width: 6rem;
  }
}
@media only screen and (max-width: 414px) {
  .formUp .formUp_infoItem {
    padding-bottom: 0.8rem;
  }
  .formUp .formUp_text {
    margin-top: 1rem;
  }
  .formUp .formUp_main {
    margin-top: 1rem;
  }
  .formUp .formUp_title {
    padding-top: 2rem;
  }
}
@media only screen and (max-width: 320px) {
  .formUp .formUp_title {
    padding-top: 0;
  }
  .formUp .formUp_infoItem {
    line-height: 1rem;
    padding-bottom: 0.5rem;
  }
  .formUp .formUp_qrCode {
    width: 5rem;
    height: 5rem;
    /* position: relative;
      top: 8rem; */
  }
}
</style>
