<template>
  <div class="newsDetails">
    <v-header :activeId="4"></v-header>
    <boxOne :title="title" :content="content" :bg="bg"></boxOne>
    <div class="box2 box">
      <div class="widthBox">
        <div class="details_header">
          <div class="details_header_title">{{ newsInfo.title }}</div>
          <div class="details_header_info">
            <span>来源: 顾诚智能</span>
            <span>作者: {{ newsInfo.author }}</span>
            <span>发布时间: {{ newsInfo.create_time | format }}</span>
          </div>
        </div>
        <div class="details_content" v-html="newsInfo.content"></div>
        <div class="pagination">
          <div class="prev" @click="prev">{{ index >= total ? "没有了" : "上一篇" }}</div>
          <div class="next" @click="next">{{ index == 1 ? "没有了" : "下一篇" }}</div>
        </div>
      </div>
    </div>
    <v-footer></v-footer>
    <!-- <backTop class="home_backTop" @switchBoard="switchBoard"></backTop> -->
    <board :boardShow="boardShow" @switchBoard="switchBoard"></board>
  </div>
</template>

<script>
import header from "../../components/header";
import boxOne from "../../components/boxOne";
import footer from "../../components/footer";
// import backTop from "../../components/backTop";
import board from "../../components/board";
import utils from "../../components/utils";

export default {
  name: "newsDetails",
  data() {
    return {
      imgUrl: "",
      title: "顾诚 x VR：新闻热点聚集地",
      content: "查看顾诚实时新闻动态，了解VR行业新闻热点",
      bg: "banner-news.jpg",
      index: 0,
      total: 0,
      newsInfo: {},
      boardShow: false,
    };
  },
  filters: {
    format(val) {
      return utils.format(val, "yyyy-MM-dd");
    },
  },
  methods: {
    // 留言板开关
    switchBoard(val) {
      this.boardShow = val;
    },
    prev() {
      if (this.index < this.total) {
        this.index++;
        this.getNewsInfo();
        document.documentElement.scrollTop = 0;
      }
    },
    next() {
      if (this.index) {
        this.index--;
        this.getNewsInfo();
        document.documentElement.scrollTop = 0;
      }
    },
    // 获取新闻信息
    getNewsInfo() {
      this.$axios
        .post("/news/getNewsList", {
          pageInfo: {
            rows: 1,
            page: 1,
          },
          reqData: {
            id: this.index,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            let info = res.data.news[0];
            info.content = JSON.parse(info.content);
            document
              .querySelector('meta[name="keywords"]')
              .setAttribute("content", info.seo_keyword);
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", info.seo_desc);
            document.title = info.seo_title;
            this.newsInfo = info;
            this.total = res.data.total;
          }
        });
    },
  },
  created() {
    this.imgUrl = this.$imgUrl;
  },
  mounted() {
    this.index = this.$route.query.id;
    this.getNewsInfo();
  },
  components: {
    "v-header": header,
    "v-footer": footer,
    boxOne: boxOne,
    // backTop: backTop,
    board: board,
  },
};
</script>

<style scoped>
.details_header {
  margin-top: 4rem;
  border-bottom: 1px solid #e8e8e8;
  text-align: center;
}
.details_header_title {
  font-size: 1.6rem;
  color: #333333;
  font-weight: bold;
}
.details_header_info {
  padding: 1rem 0;
  color: #999999;
  display: flex;
  font-size: 0.7rem;
  justify-content: center;
}
.details_header_info span {
  padding-right: 3rem;
}
.details_content {
  white-space: pre-wrap;
  color: #333;
  line-height: 2rem;
  border-bottom: 1px solid #e8e8e8;
  padding-bottom: 3rem;
  margin-top: 1rem;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin: 3rem 0 4rem 0;
  color: #333333;
}
.prev,
.next {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #ededed;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  opacity: 0.7;
  transition: all 0.2s;
}

.prev:hover,
.next:hover {
  opacity: 1;
  font-size: 18px;
}
</style>
