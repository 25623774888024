<template>
  <div id="footer" class="footer">
    <div class="widthBox_mobile">
      <div class="footer_list">
        <div class="attention">
          <div class="attention_title">
            <img :src="imgUrl + 'LOGO-gc-2.png'" alt="" />
            广东顾诚智能科技有限公司
          </div>
          <div class="attention_content" @touchstart="codeARIsShow">
            <img :src="imgUrl + 'icon/footer-wechat.png'" />
          </div>
        </div>
        <el-collapse accordion class="collapse">
          <el-collapse-item
            v-for="(item, index) in aboutList"
            :key="index"
            :title="item.title"
          >
            <ul class="about">
              <li
                class="about_item"
                @click="navTo(item.url, items.id, items.url)"
                v-for="(items, indexs) in item.children"
                :key="indexs"
              >
                {{ items.name }}
              </li>
            </ul>
          </el-collapse-item>

          <ul class="contact_list">
            <li class="contact_item">
              <img :src="imgUrl + 'icon/footer-contact.png'" />
              <div class="font-tel">
                咨询联系：
                <span>0769 - 8899 3721</span>
                <span>13922304039</span>
              </div>
              <a href="tel:13922304039">立即呼叫</a>
            </li>
            <li class="contact_item">
              <p class="contact_title">
                广东省·东莞市·南城区·莞太路篁村段22号·福民大厦505
              </p>
            </li>
          </ul>
        </el-collapse>
        <div class="company">
          <a href="https://beian.miit.gov.cn">
            Copyright © {{ year }} 广东顾诚智能科技有限公司，All Rights
            Reserved. 粤ICP备16107832号</a
          >
        </div>
      </div>
    </div>
    <div class="codeAR" v-if="codeAR">
      <div class="codeAR_box">
        <div class="codeAR_main">
          <img :src="imgUrl + 'qrCode.jpg'" alt />
          <div class="codeAR_box_title">扫一扫识别二维码，关注我们</div>
        </div>
        <img
          :src="imgUrl + 'close-white.png'"
          alt
          class="codeAR_close"
          @touchstart="codeARIsShow"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footer_mobile",
  data() {
    return {
      imgUrl: "",
      codeAR: 0,
      year: "",
      aboutList: [
        {
          title: "关于顾诚",
          url: "/callMe_mobile",
          children: [
            {
              id: "company",
              name: "公司介绍",
            },
            {
              id: "callMe",
              name: "联系我们",
            },
          ],
        },
        {
          title: "行业解决方案",
          url: "/caseShow_mobile",
          children: [
            {
              id: "vrExhibition",
              name: "企业展厅",
            },
            {
              id: "vrMuseum",
              name: "数字文博",
            },
            {
              id: "vrHotel",
              name: "民宿酒店",
            },
            {
              id: "vrHouse",
              name: "VR看房",
            },
            {
              id: "vrMore",
              name: "更多行业",
            },
          ],
        },
        {
          title: "服务方案",
          url: "/servicePlan_mobile",
          children: [
            {
              id: "service",
              name: "服务流程",
            },
            {
              id: "offerPlan",
              name: "报价方案",
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    codeARIsShow() {
      this.codeAR = !this.codeAR;
    },
    navTo(url, id, flag) {
      if (flag) {
        if (this.$route.name == id) return;
        this.$router.push({
          path: id,
        });
      } else {
        this.$router.push({
          path: url,
          query: { id: id, random: Math.random().toFixed(3) },
        });
      }
    },
  },
  mounted() {
    this.imgUrl = this.$imgUrl;
    let years = new Date();
    this.year = years.getFullYear();
  },
};
</script>

<style>
.footer {
  height: 100%;
  background: #f8f8f8;
  position: relative;
}
.footer_title {
  text-align: center;
  color: #333333;
  font-size: 18px;
  margin: 4rem auto 2rem;
}
.footer_list {
  padding-top: 3rem;
  height: 100%;
  margin-top: 3rem;
  padding-bottom: 40px;
  box-sizing: border-box;
}
.attention_content img {
  width: 36px;
  height: 36px;
  margin-right: 0.2rem;
}
.attention_title > img {
  width: 33px;
  height: 28px;
  margin-right: 10px;
}
.attention_title {
  font-size: 14px;
  font-family: Source Han Sans SC;
  font-weight: bold;
  color: #333333;
  display: flex;
  align-items: center;
}
.attention {
  margin: 0 auto 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.collapse {
  margin: 0 auto;
  padding-bottom: 0.5rem;
}
.el-collapse {
  border-bottom: 1px solid #e8e8e8;
}
.el-collapse-item__header {
  background: none;
  border: none;
  font-size: 16px;
}
.contact_list {
  margin: 2rem auto 0;
  font-size: 14px;
  color: #333333;
}
.contact_item {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact_item > img {
  width: 24px;
  height: 24px;
}

.font-tel {
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #333333;
}

.font-tel > span {
  font-size: 16px;
  font-family: SimHei;
  font-weight: bold;
  color: #333333;
}

.contact_item > a {
  height: 32px;
  background: linear-gradient(90deg, #30c0fc, #1e7ff6);
  border-radius: 16px;
  color: #fff;
  font-weight: bold;
  text-align: center;
  line-height: 32px;
  padding: 0 10px;
  font-size: 13px;
  box-sizing: border-box;
}

.contact_title {
  width: 100%;
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  text-align: center;
}

.company {
  margin-top: 20px;
}
.company > a {
  margin: 0 auto;
  font-size: 12px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  line-height: 23px;
}
.codeAR {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}
.codeAR_box {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.codeAR_main {
  width: 100%;
  height: 17rem;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.codeAR_main img {
  width: 13rem;
  height: 13rem;
  margin-top: 1rem;
}
.codeAR_box_title {
  color: #999999;
  margin-top: 1rem;
  font-size: 14px;
}
.codeAR_close {
  width: 1rem;
  margin: 1rem auto 0;
}
.about {
  display: flex;
  flex-wrap: wrap;
}
.about_item {
  width: 30%;
  height: 2rem;
  line-height: 1.9rem;
  text-align: center;
  margin: 0.5rem 1.5%;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  font-size: 16px;
  color: #666666;
}
::-webkit-scrollbar {
  display: none;
}
.el-collapse-item__wrap {
  background: inherit;
}
</style>
